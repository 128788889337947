import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'
import toast from 'react-hot-toast'

const Wrap = styled.div`
  max-width: 1200px;
  /* background-color: #133b92; */
  background-color: #086102;

  color: white;
  background-size: cover;
  margin: 4rem auto;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 4rem;
  min-height: 100%;
  margin-bottom: 2rem;
  position: relative;
  @media (max-width: 1200px) {
    max-width: 1000px;
  }
  @media (max-width: 1000px) {
    max-width: 900px;
  }
`
const SplitBox = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: green;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
`
const InputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormText = styled.span`
  font-size: 1.1rem;
  margin-right: 10px;
  font-weight: 500;
  min-width: 170px;
  @media (max-width: 900px) {
    font-size: 0.8rem;
    min-width: 100px;
    font-weight: 400;
    margin-right: 5px;
  }
`
const ErrMsg = styled.p`
  font-size: 0.8rem;
  color: #e8ff3b;
  padding: 0.5rem;
  text-align: start;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const InputSetNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const FormTextNew = styled(FormText)`
  margin-right: 0px;
  min-width: 100px;
`
const FormInputNew = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const MiddleText = styled(FormText)`
  font-size: 1.2rem;
  text-align: left;
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const InputSetTwo = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 20px;
  padding: 1.5rem 0;
  width: 98%;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`

const FormTextField = styled.textarea`
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 4px;
  outline: none;
  resize: none;
`
const FieldsHeading = styled.p`
  font-size: 1.5rem;
  text-align: start;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline;
  font-style: italic;
  color: #ffffff;
  padding: 0.5rem;
  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem;
  }
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`

const SelectCat = styled.select`
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #0b0a45;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
`
const SelectOpt = styled.option`
  /* width: 50%; */
  display: flex;
  background-color: #0b0a45;

  justify-content: center;
  align-items: center;
`
const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 800;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #0091ff;
  color: #ffffff;
`
const NextBtn = styled(SubmitBtn)`
  background: #ffee00;
  color: #000000;
  border: none;
`
const PrevBtn = styled(NextBtn)`
  background: #ff0000;
  border: none;
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`

const FormInput = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
`

const CheckboxLabel = styled.label`
  margin: 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const SelectedSports = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
`

const SportChip = styled.div`
  padding: 8px 12px;
  background-color: #0b0a45;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;
`

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
`
const EditCamper = () => {
  // all the states here
  const [showCard, setShowCard] = useState(false)

  const [selectedSports, setSelectedSports] = useState([])
  const [selectedClubs, setSelectedClubs] = useState([])

  const [sportsInterested, setSportsInterested] = useState('')

  const [classInfo, setClassInfo] = useState('')
  const [personalInfo, setPersonalInfo] = useState('')
  const [guardianInfo, setGuardianInfo] = useState({
    guardianName: { motherName: '', fatherName: '' },
    guardianOccupation: {
      motherOcc: '',
      fatherOcc: '',
      motherCom: '',
      fatherCom: '',
    },
    guardianContact: {
      fatherCell: '',
      motherCell: '',
      fatherCNIC: '',
      motherCNIC: '',
      fatherEmail: '',
      fatherNationality: '',
    },
    otherInfo: { address: '', flang: '', olang: '' },
  })

  const [siblings, setSiblings] = useState({
    siblingOne: { name: '', grade: '', section: '' },
    siblingTwo: { name: '', grade: '', section: '' },
    siblingThree: { name: '', grade: '', section: '' },
    siblingFour: { name: '', grade: '', section: '' },
  })

  const [medicalHistory, setMedicalHistory] = useState({
    allergies: '',
    asthma: '',
    speechDefect: '',
    visionProblem: '',
    hearingProblem: '',
    learningProblem: '',
    compulsiveDisorder: '',
    meditation: '',
    otherCondition: '',
  })
  const [interestIn, setInterestIn] = useState({
    sports: [],
    clubs: [],
  })

  const [showPageOne, setShowPageOne] = useState(true)
  const params = useParams()
  const navigate = useNavigate()
  // all funcs

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      // If the date is not valid, return an empty string or handle it accordingly
      return ''
    }
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const handleClubChange = (event) => {
    const club = event.target.name
    if (event.target.checked) {
      setSelectedClubs((prev) => [...prev, club])
    } else {
      setSelectedClubs((prev) => prev.filter((c) => c !== club))
    }
  }

  const GradeOptions = [
    'Choose',
    'PG',
    'Nursery',
    'KG',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
  ]
  const GenderOption = ['Choose', 'Male', 'Female', 'Prefer Not To Say']
  const SportsOptions = [
    'Cricket',
    'Football',
    'Hockey',
    'Badminton',
    'Table Tennis',
    'Basketball',
    'Volleyball',
    'Squash',
  ]
  const ClubsOptions = ['Riding', 'Swimming', 'Archery', 'Shooting']

  const { data, status } = useQuery(
    'edit-student',
    async () => {
      // setIsLoading(true)
      const res = await axiosInstance.get(`summerCamp/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        const defaultGuardianInfo = {
          guardianName: { motherName: '', fatherName: '' },
          guardianOccupation: {
            motherOcc: '',
            fatherOcc: '',
            motherCom: '',
            fatherCom: '',
          },
          guardianContact: {
            fatherCell: '',
            motherCell: '',
            fatherCNIC: '',
            motherCNIC: '',
            fatherEmail: '',
            fatherNationality: '',
          },
          otherInfo: { address: '', flang: '', olang: '' },
        }
        setSiblings(
          data.siblings || {
            siblingOne: { name: '', grade: '', section: '' },
            siblingTwo: { name: '', grade: '', section: '' },
            siblingThree: { name: '', grade: '', section: '' },
            siblingFour: { name: '', grade: '', section: '' },
          }
        )
        setMedicalHistory(
          data.medicalHistory || {
            allergies: '',
            asthma: '',
            speechDefect: '',
            visionProblem: '',
            hearingProblem: '',
            learningProblem: '',
            compulsiveDisorder: '',
            meditation: '',
            otherCondition: '',
          }
        )
        setClassInfo(data.classInfo || {})
        setInterestIn(
          data.interestIn || {
            sports: [],
            clubs: [],
          }
        )
        setPersonalInfo(data.personalInfo || {})
        setGuardianInfo({
          ...defaultGuardianInfo,
          ...data.guardianInfo,
          guardianName: {
            ...defaultGuardianInfo.guardianName,
            ...data.guardianInfo?.guardianName,
          },
          guardianOccupation: {
            ...defaultGuardianInfo.guardianOccupation,
            ...data.guardianInfo?.guardianOccupation,
          },
          guardianContact: {
            ...defaultGuardianInfo.guardianContact,
            ...data.guardianInfo?.guardianContact,
          },
          otherInfo: {
            ...defaultGuardianInfo.otherInfo,
            ...data.guardianInfo?.otherInfo,
          },
        })
      },
    }
  )

  // all the functions here
  const handleStudent = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosInstance.put(`summerCamp/${params.id}`, {
        sportsInterested,
        personalInfo,
        classInfo,
        siblings,
        medicalHistory,
        guardianInfo,
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Form Updated Successfully', {
          duration: 4000,
        })
        navigate(`/students/summer-camp/view/${params.id}`)
      } else {
        toast.error('Update Failed. Please try again!', {
          duration: 4000,
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  const handleSelectSport = (event) => {
    const sport = event.target.value
    if (sport) {
      setSelectedSports((prev) => [...prev, sport])
    }
  }

  const handleRemoveSport = (sport) => {
    setSelectedSports((prev) => prev.filter((s) => s !== sport))
  }

  const availableSports = SportsOptions.filter(
    (sport) => !selectedSports.includes(sport)
  )

  return (
    <Layout>
      <Topbar title='Summer Camp / Add' />
      <>
        <Wrap id='booking-form-wrap'>
          {showPageOne && (
            <>
              <FieldsHeading>Summer Camp Form</FieldsHeading>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Student's Name: </FormText>
                    <FormInput
                      placeholder='Enter Name...'
                      type='text'
                      value={personalInfo.name || ''}
                      onChange={(e) =>
                        setPersonalInfo((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Previous School: </FormText>
                    <FormInput
                      placeholder='Enter Previous School...'
                      type='text'
                      value={personalInfo.prevSchool}
                      onChange={(e) =>
                        setPersonalInfo((prev) => ({
                          ...prev,
                          prevSchool: e.target.value,
                        }))
                      }
                      id='prevSchool'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputSetTwo>
                <FormText>Class: </FormText>
                <SelectCat
                  name='grade'
                  value={classInfo.className}
                  id='grade'
                  onChange={(e) =>
                    setClassInfo((prev) => ({
                      ...prev,
                      className: e.target.value,
                    }))
                  }
                >
                  {GradeOptions.map((category) => (
                    <SelectOpt key={category} value={category}>
                      {category}
                    </SelectOpt>
                  ))}
                </SelectCat>
              </InputSetTwo>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>DOB: </FormText>
                    <FormInput
                      type='date'
                      value={formatDate(personalInfo.dateOfBirth)}
                      id='dob'
                      onChange={(e) =>
                        setPersonalInfo((prev) => ({
                          ...prev,
                          dateOfBirth: e.target.value,
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSetTwo>
                    <FormText>Gender: </FormText>
                    <SelectCat
                      name='gender'
                      value={personalInfo.gender}
                      onChange={(e) =>
                        setPersonalInfo((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }
                    >
                      {GenderOption.map((category) => (
                        <SelectOpt key={category} value={category}>
                          {category}
                        </SelectOpt>
                      ))}
                    </SelectCat>
                  </InputSetTwo>
                </ErrorWrap>
              </InputWrap>

              <SplitBox>Father's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Father Name...'
                      type='text'
                      value={guardianInfo.guardianName.fatherName}
                      id='fname'
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianName: {
                            ...prev.guardianName,
                            fatherName: e.target.value,
                          },
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> CNIC: </FormText>
                    <FormInput
                      placeholder='Enter CNIC ...'
                      type='text'
                      value={guardianInfo.guardianContact.fatherCNIC}
                      id='fcnic'
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianContact: {
                            ...prev.guardianContact,
                            fatherCNIC: e.target.value,
                          },
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#...'
                      type='text'
                      value={guardianInfo.guardianContact.fatherCell}
                      id='fcell'
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianContact: {
                            ...prev.guardianContact,
                            fatherCell: e.target.value,
                          },
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Occupation: </FormText>
                    <FormInput
                      placeholder='Enter Fathers occupation...'
                      type='text'
                      value={guardianInfo.guardianOccupation.fatherOcc}
                      id='foccupation'
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianOccupation: {
                            ...prev.guardianOccupation,
                            fatherOcc: e.target.value,
                          },
                        }))
                      }
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Mother's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Mothers Name...'
                      type='text'
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianName: {
                            ...prev.guardianName,
                            motherName: e.target.value,
                          },
                        }))
                      }
                      value={guardianInfo.guardianName.motherName}
                      id='mname'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#..'
                      type='text'
                      value={guardianInfo.guardianContact.motherCell}
                      onChange={(e) =>
                        setGuardianInfo((prev) => ({
                          ...prev,
                          guardianContact: {
                            ...prev.guardianContact,
                            motherCell: e.target.value,
                          },
                        }))
                      }
                      id='mcell'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Sports & Club Preferences</SplitBox>
              <InputWrap>
                <InputSetTwo>
                  <FormText>Sports:</FormText>
                  <SelectCat
                    name='sportsInterested'
                    onChange={handleSelectSport}
                    value=''
                  >
                    <SelectOpt value=''>Choose</SelectOpt>
                    {availableSports.map((option) => (
                      <SelectOpt key={option} value={option}>
                        {option}
                      </SelectOpt>
                    ))}
                  </SelectCat>
                </InputSetTwo>
              </InputWrap>
              <InputSet>
                <SelectedSports>
                  {selectedSports.map((sport) => (
                    <SportChip key={sport}>
                      {sport}
                      <RemoveButton onClick={() => handleRemoveSport(sport)}>
                        &times;
                      </RemoveButton>
                    </SportChip>
                  ))}
                </SelectedSports>
              </InputSet>
              <InputWrap>
                <InputSet>
                  <FormText>Clubs:</FormText>
                  <CheckboxContainer>
                    {ClubsOptions.map((club) => (
                      <CheckboxLabel key={club}>
                        <Checkbox
                          type='checkbox'
                          name={club}
                          checked={selectedClubs.includes(club)}
                          onChange={handleClubChange}
                        />
                        {club}
                      </CheckboxLabel>
                    ))}
                  </CheckboxContainer>
                </InputSet>
              </InputWrap>

              <SubSet>
                <NextBtn onClick={handleStudent}>
                  <span>Update</span>
                </NextBtn>
              </SubSet>
            </>
          )}
        </Wrap>
      </>
    </Layout>
  )
}

export default EditCamper
