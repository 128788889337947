import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'
import DisplayCardUpdated from './DisplayCardUpdated'

const Wrap = styled.div`
  max-width: 1200px;
  /* background-color: #133b92; */
  background-color: #086102;

  color: white;
  background-size: cover;
  margin: 4rem auto;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 4rem;
  min-height: 100%;
  margin-bottom: 2rem;
  position: relative;
  @media (max-width: 1200px) {
    max-width: 1000px;
  }
  @media (max-width: 1000px) {
    max-width: 900px;
  }
`
const SplitBox = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: green;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
`
const InputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormText = styled.span`
  font-size: 1.1rem;
  margin-right: 10px;
  font-weight: 500;
  min-width: 170px;
  @media (max-width: 900px) {
    font-size: 0.8rem;
    min-width: 100px;
    font-weight: 400;
    margin-right: 5px;
  }
`

const InputSetNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const FormTextNew = styled(FormText)`
  margin-right: 0px;
  min-width: 100px;
`
const FormInputNew = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const MiddleText = styled(FormText)`
  font-size: 1.2rem;
  text-align: left;
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const InputSetTwo = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 20px;
  padding: 1.5rem 0;
  width: 98%;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`

const FieldsHeading = styled.p`
  font-size: 1.5rem;
  text-align: start;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline;
  font-style: italic;
  color: #ffffff;
  padding: 0.5rem;
  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem;
  }
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`

const SelectCat = styled.select`
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #0b0a45;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
`
const SelectOpt = styled.option`
  /* width: 50%; */
  display: flex;
  background-color: #0b0a45;

  justify-content: center;
  align-items: center;
`
const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 800;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #0091ff;
  color: #ffffff;
`
const NextBtn = styled(SubmitBtn)`
  background: #ffee00;
  color: #000000;
  border: none;
`
const PrevBtn = styled(NextBtn)`
  background: #ff0000;
  border: none;
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`

const FormInput = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
`

const CheckboxLabel = styled.label`
  margin: 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const SelectedSports = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
`

const SportChip = styled.div`
  padding: 8px 12px;
  background-color: #0b0a45;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;
`

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
`
const EditStudent = () => {
  // all the states here
  const [showCard, setShowCard] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState('')
  const [prevSchool, setPrevSchool] = useState('')
  const [sNationality, setSNationality] = useState('')
  const [dob, setDob] = useState('')
  const [grade, setGrade] = useState('')
  const [gender, setGender] = useState('')
  const [fname, setFname] = useState('')
  const [fnationality, setFnationality] = useState('')
  const [fcnic, setFcninc] = useState('')
  const [femail, setFemail] = useState('')
  const [selectedSports, setSelectedSports] = useState([])
  const [selectedClubs, setSelectedClubs] = useState([])
  const [fcell, setFcell] = useState('')
  const [foccupation, setFoccupation] = useState('')
  const [fcompany, setFcompany] = useState('')
  const [mname, setMname] = useState('')
  const [moccupation, setMoccupation] = useState('')
  const [mcell, setMcell] = useState('')
  const [mcompany, setMcompany] = useState('')
  const [flang, setFlang] = useState('')
  const [olang, setOLang] = useState('')
  const [address, setAddress] = useState('')
  const [others, setOthers] = useState('')

  const [siblings, setSiblings] = useState({
    siblingOne: { name: '', grade: '', section: '' },
    siblingTwo: { name: '', grade: '', section: '' },
    siblingThree: { name: '', grade: '', section: '' },
    siblingFour: { name: '', grade: '', section: '' },
  })

  const [medicalHistory, setMedicalHistory] = useState({
    allergies: '',
    asthma: '',
    speechDefect: '',
    visionProblem: '',
    hearingProblem: '',
    learningProblem: '',
    compulsiveDisorder: '',
    medication: '',
    otherCondition: '',
  })

  const [showPageOne, setShowPageOne] = useState(true)
  const params = useParams()
  // all funcs

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      // If the date is not valid, return an empty string or handle it accordingly
      return ''
    }
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const handleClubChange = (event) => {
    const club = event.target.name
    if (event.target.checked) {
      setSelectedClubs((prev) => [...prev, club])
    } else {
      setSelectedClubs((prev) => prev.filter((c) => c !== club))
    }
  }

  const GradeOptions = [
    'Choose',
    'PG',
    'Nursery',
    'KG',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
  ]
  const GenderOption = ['Choose', 'Male', 'Female', 'Prefer Not To Say']
  const SportsOptions = [
    'Cricket',
    'Football',
    'Hockey',
    'Badminton',
    'Table Tennis',
    'Basketball',
    'Volleyball',
    'Squash',
  ]
  const ClubsOptions = ['Riding', 'Swimming', 'Archery', 'Shooting']

  const { data, status } = useQuery(
    'edit-admission',
    async () => {
      // setIsLoading(true)
      const res = await axiosInstance.get(`/admission/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setName(data.name || '')
        setPrevSchool(data.prevSchool || '')
        setDob(formatDate(data.dob) || '')
        setGrade(data.grade || '')
        setSNationality(data.sNationality || '')
        setGender(data.gender || '')
        setFname(data.fname || '')
        setFcninc(data.fcnic || '')
        setFemail(data.femail || '')
        setFcell(data.fcell || '')
        setFoccupation(data.foccupation || '')
        setFnationality(data.fnationality || '')
        setFcompany(data.fcompany || '')
        setMcompany(data.mcompany || '')
        setMname(data.mname || '')
        setMoccupation(data.moccupation || '')
        setMcell(data.mcell || '')
        setAddress(data.address || '')
        setFlang(data.flang || '')
        setOLang(data.olang || '')
        setSiblings(
          data.siblings || {
            siblingOne: { name: '', grade: '', section: '' },
            siblingTwo: { name: '', grade: '', section: '' },
            siblingThree: { name: '', grade: '', section: '' },
            siblingFour: { name: '', grade: '', section: '' },
          }
        )
        setMedicalHistory(
          data.medicalHistory || {
            allergies: '',
            asthma: '',
            speechDefect: '',
            visionProblem: '',
            hearingProblem: '',
            learningProblem: '',
            compulsiveDisorder: '',
            medication: '',
            otherCondition: '',
          }
        )
        setOthers(data.fname || '')
        setIsLoading(false)
      },
    }
  )
  // all the functions here
  const handleStudent = async (e) => {
    e.preventDefault()
    setShowCard(true)

    try {
      setIsLoading(true)
      await axiosInstance.put(`admission/${params.id}`, {
        name,
        prevSchool,
        dob,
        grade,
        gender,
        selectedSports,
        selectedClubs,
        sNationality,
        fname,
        fcnic,
        fcell,
        femail,
        foccupation,
        fcompany,
        fnationality,
        mname,
        moccupation,
        mcompany,
        mcell,
        address,
        others,
        flang,
        olang,
        siblings: {
          siblingOne: {
            name: siblings.siblingOne.name,
            grade: siblings.siblingOne.grade,
            section: siblings.siblingOne.section,
          },
          siblingTwo: {
            name: siblings.siblingTwo.name,
            grade: siblings.siblingTwo.grade,
            section: siblings.siblingTwo.section,
          },
          siblingThree: {
            name: siblings.siblingThree.name,
            grade: siblings.siblingThree.grade,
            section: siblings.siblingThree.section,
          },
          siblingFour: {
            name: siblings.siblingFour.name,
            grade: siblings.siblingFour.grade,
            section: siblings.siblingFour.section,
          },
        },
        medicalHistory: {
          allergies: medicalHistory.allergies,
          asthma: medicalHistory.asthma,
          speechDefect: medicalHistory.speechDefect,
          visionProblem: medicalHistory.visionProblem,
          hearingProblem: medicalHistory.hearingProblem,
          learningProblem: medicalHistory.learningProblem,
          compulsiveDisorder: medicalHistory.compulsiveDisorder,
          medication: medicalHistory.medication,
          otherCondition: medicalHistory.otherCondition,
        },
      })
      // navigate(`/students/all-admissions/view/${params.id}`)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectSport = (event) => {
    const sport = event.target.value
    if (sport) {
      setSelectedSports((prev) => [...prev, sport])
    }
  }

  const handleRemoveSport = (sport) => {
    setSelectedSports((prev) => prev.filter((s) => s !== sport))
  }

  const availableSports = SportsOptions.filter(
    (sport) => !selectedSports.includes(sport)
  )

  const handleSiblingChange = (siblingKey, field, value) => {
    setSiblings((prevSiblings) => ({
      ...prevSiblings,
      [siblingKey]: {
        ...prevSiblings[siblingKey],
        [field]: value,
      },
    }))
  }

  return (
    <Layout>
      <Topbar title='Admissions / Add' />
      <>
        <Wrap id='booking-form-wrap'>
          {showPageOne && (
            <>
              <FieldsHeading>Admission Form</FieldsHeading>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Student's Name: </FormText>
                    <FormInput
                      placeholder='Enter Name...'
                      type='text'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id='name'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Previous School: </FormText>
                    <FormInput
                      placeholder='Enter Previous School...'
                      type='text'
                      value={prevSchool}
                      onChange={(e) => setPrevSchool(e.target.value)}
                      id='prevSchool'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputSetTwo>
                <FormText>Class: </FormText>
                <SelectCat
                  name='grade'
                  value={grade}
                  id='grade'
                  onChange={(e) => setGrade(e.target.value)}
                >
                  {GradeOptions.map((category) => (
                    <SelectOpt key={category} value={category}>
                      {category}
                    </SelectOpt>
                  ))}
                </SelectCat>
              </InputSetTwo>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>DOB: </FormText>
                    <FormInput
                      type='date'
                      value={formatDate(dob)}
                      id='dob'
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSetTwo>
                    <FormText>Gender: </FormText>
                    <SelectCat
                      name='gender'
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      {GenderOption.map((category) => (
                        <SelectOpt key={category} value={category}>
                          {category}
                        </SelectOpt>
                      ))}
                    </SelectCat>
                  </InputSetTwo>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Nationality: </FormText>
                    <FormInput
                      placeholder='Enter Student Nationality...'
                      type='text'
                      value={sNationality}
                      id='sNationality'
                      onChange={(e) => setSNationality(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Father's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Father Name...'
                      type='text'
                      value={fname}
                      id='fname'
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> CNIC: </FormText>
                    <FormInput
                      placeholder='Enter CNIC ...'
                      type='text'
                      value={fcnic}
                      id='fcnic'
                      onChange={(e) => setFcninc(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#...'
                      type='text'
                      value={fcell}
                      id='fcell'
                      onChange={(e) => setFcell(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Email: </FormText>
                    <FormInput
                      placeholder='Enter Fathers Email...'
                      type='text'
                      value={femail}
                      id='femail'
                      onChange={(e) => setFemail(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Nationality: </FormText>
                    <FormInput
                      placeholder='Enter Fathers Nationality...'
                      type='text'
                      value={fnationality}
                      id='fnationality'
                      onChange={(e) => setFnationality(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Occupation: </FormText>
                    <FormInput
                      placeholder='Enter Fathers occupation...'
                      type='text'
                      value={foccupation}
                      id='foccupation'
                      onChange={(e) => setFoccupation(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Company Name: </FormText>
                    <FormInput
                      placeholder='Company Name (if any)'
                      type='text'
                      value={fcompany}
                      id='fcompany'
                      onChange={(e) => setFcompany(e.target.value)}
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Mother's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Mothers Name...'
                      type='text'
                      onChange={(e) => setMname(e.target.value)}
                      value={mname}
                      id='mname'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#..'
                      type='text'
                      onChange={(e) => setMcell(e.target.value)}
                      value={mcell}
                      id='mcell'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Occupation: </FormText>
                    <FormInput
                      placeholder='Enter Occupation...'
                      type='text'
                      onChange={(e) => setMoccupation(e.target.value)}
                      value={moccupation}
                      id='moccupation'
                    />
                  </InputSet>
                </ErrorWrap>

                <ErrorWrap>
                  <InputSet>
                    <FormText>Company Name: </FormText>
                    <FormInput
                      placeholder='Company Name (if any)'
                      type='text'
                      onChange={(e) => setMcompany(e.target.value)}
                      value={mcompany}
                      id='mcompany'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Additional Information</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>First Language spoken at home: </FormText>
                    <FormInput
                      placeholder='Enter Language...'
                      type='text'
                      onChange={(e) => setFlang(e.target.value)}
                      value={flang}
                      id='flang'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Any Other: </FormText>
                    <FormInput
                      placeholder='Enter Language...'
                      type='text'
                      onChange={(e) => setOLang(e.target.value)}
                      value={olang}
                      id='olang'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Address: </FormText>
                    <FormInput
                      placeholder='Enter Address...'
                      type='text'
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      id='address'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Other Information / Notes :</FormText>
                    <FormInput
                      placeholder='Other info...'
                      type='text'
                      onChange={(e) => setOthers(e.target.value)}
                      value={others}
                      id='others'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <SubSet>
                <NextBtn onClick={() => setShowPageOne(false)}>
                  <span>Next</span>
                </NextBtn>
              </SubSet>
            </>
          )}
          {!showPageOne && (
            <>
              <SplitBox>Sports & Club Preferences</SplitBox>
              <InputWrap>
                <InputSetTwo>
                  <FormText>Sports:</FormText>
                  <SelectCat
                    name='sportsInterested'
                    onChange={handleSelectSport}
                    value=''
                  >
                    <SelectOpt value=''>Choose</SelectOpt>
                    {availableSports.map((option) => (
                      <SelectOpt key={option} value={option}>
                        {option}
                      </SelectOpt>
                    ))}
                  </SelectCat>
                </InputSetTwo>
              </InputWrap>
              <InputSet>
                <SelectedSports>
                  {selectedSports.map((sport) => (
                    <SportChip key={sport}>
                      {sport}
                      <RemoveButton onClick={() => handleRemoveSport(sport)}>
                        &times;
                      </RemoveButton>
                    </SportChip>
                  ))}
                </SelectedSports>
              </InputSet>
              <InputWrap>
                <InputSet>
                  <FormText>Clubs:</FormText>
                  <CheckboxContainer>
                    {ClubsOptions.map((club) => (
                      <CheckboxLabel key={club}>
                        <Checkbox
                          type='checkbox'
                          name={club}
                          checked={selectedClubs.includes(club)}
                          onChange={handleClubChange}
                        />
                        {club}
                      </CheckboxLabel>
                    ))}
                  </CheckboxContainer>
                </InputSet>
              </InputWrap>
              <SplitBox>Siblings Information</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <MiddleText>
                    Name of brothers and sisters studying in the same system....
                  </MiddleText>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <InputSet>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Name ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange('siblingOne', 'name', e.target.value)
                    }
                    value={siblings.siblingOne.name}
                    id='SiblingOneName'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Class ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange('siblingOne', 'grade', e.target.value)
                    }
                    value={siblings.siblingOne.grade}
                    id='SiblingOneClass'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Section ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingOne',
                        'section',
                        e.target.value
                      )
                    }
                    value={siblings.siblingOne.section}
                    id='SiblingOneSection'
                  />
                </InputSet>
              </InputWrap>
              <InputWrap>
                <InputSet>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Name ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange('siblingTwo', 'name', e.target.value)
                    }
                    value={siblings.siblingTwo.name}
                    id='SiblingTwoName'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Class ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange('siblingTwo', 'grade', e.target.value)
                    }
                    value={siblings.siblingTwo.grade}
                    id='SiblingTwoClass'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Section ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingTwo',
                        'section',
                        e.target.value
                      )
                    }
                    value={siblings.siblingTwo.section}
                    id='SiblingTwoSection'
                  />
                </InputSet>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Name ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingThree',
                        'name',
                        e.target.value
                      )
                    }
                    value={siblings.siblingThree.name}
                    id='SiblingThreeName'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Class ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingThree',
                        'grade',
                        e.target.value
                      )
                    }
                    value={siblings.siblingThree.grade}
                    id='SiblingThreeClass'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Section ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingThree',
                        'section',
                        e.target.value
                      )
                    }
                    value={siblings.siblingThree.section}
                    id='SiblingThreeSection'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Name ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange('siblingFour', 'name', e.target.value)
                    }
                    value={siblings.siblingFour.name}
                    id='SiblingFourName'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Class ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingFour',
                        'grade',
                        e.target.value
                      )
                    }
                    value={siblings.siblingFour.grade}
                    id='SiblingFourClass'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    placeholder='Enter Section ...'
                    type='text'
                    onChange={(e) =>
                      handleSiblingChange(
                        'siblingFour',
                        'section',
                        e.target.value
                      )
                    }
                    value={siblings.siblingFour.section}
                    id='SiblingFourSection'
                  />
                </InputSetNew>
              </InputWrap>
              <SplitBox>Medical History</SplitBox>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Allergies: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.allergies'
                    placeholder='Any Allergy? ...'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        allergies: e.target.value,
                      }))
                    }
                    value={medicalHistory.allergies || ''}
                    id='allergies'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Asthma: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.asthma'
                    placeholder='Asthma?'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        asthma: e.target.value,
                      }))
                    }
                    value={medicalHistory.asthma || ''}
                    id='asthma'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Speech Defects: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.speechDefect'
                    placeholder='Enter Defects...'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        speechDefect: e.target.value,
                      }))
                    }
                    value={medicalHistory.speechDefect || ''}
                    id='speechDefect'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Vision Problems : </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.visionProblem'
                    placeholder='Any Vision Problems?'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        visionProblem: e.target.value,
                      }))
                    }
                    value={medicalHistory.visionProblem || ''}
                    id='visionProblem'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Hearing Problems: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.hearingProblem'
                    placeholder='Any Hearing Problems?'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        hearingProblem: e.target.value,
                      }))
                    }
                    value={medicalHistory.hearingProblem || ''}
                    id='hearingProblem'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Learning Difficulties: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.learningProblem'
                    placeholder='Any Learning Difficulties?'
                    type='text'
                    onChange={(e) =>
                      setMedicalHistory((prev) => ({
                        ...prev,
                        learningProblem: e.target.value,
                      }))
                    }
                    value={medicalHistory.learningProblem || ''}
                    id='learningProblem'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Compulsive Disorders: </FormText>
                    <FormInputNew
                      name='medicalHistory.compulsiveDisorder'
                      placeholder='Any Compulsive Disorders?'
                      type='text'
                      onChange={(e) =>
                        setMedicalHistory((prev) => ({
                          ...prev,
                          compulsiveDisorder: e.target.value,
                        }))
                      }
                      value={medicalHistory.compulsiveDisorder || ''}
                      id='compulsiveDisorder'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Is your child on any medication? </FormText>
                    <FormInputNew
                      name='medicalHistory.medication'
                      placeholder='medication?'
                      type='text'
                      onChange={(e) =>
                        setMedicalHistory((prev) => ({
                          ...prev,
                          medication: e.target.value,
                        }))
                      }
                      value={medicalHistory.medication || ''}
                      id='medication'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>
                      Any other conditions which may affect your child's
                      activities in school?
                    </FormText>
                    <FormInputNew
                      name='medicalHistory.otherCondition'
                      placeholder='Details Here...'
                      type='text'
                      onChange={(e) =>
                        setMedicalHistory((prev) => ({
                          ...prev,
                          otherCondition: e.target.value,
                        }))
                      }
                      value={medicalHistory.otherCondition || ''}
                      id='otherCondition'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <SubSet>
                <PrevBtn onClick={() => setShowPageOne(true)}>
                  <span>Previous</span>
                </PrevBtn>
                <SubmitBtn
                  style={{ marginLeft: '10px' }}
                  onClick={handleStudent}
                >
                  Update
                </SubmitBtn>
              </SubSet>
            </>
          )}
        </Wrap>
        {showCard && (
          <CardBase>
            <DisplayCardUpdated />
          </CardBase>
        )}
      </>
    </Layout>
  )
}

export default EditStudent
