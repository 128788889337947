import React from 'react'
import styled from 'styled-components'
import Layout from '../Layout'
import FinanceBar from '../components/Finance/FinanceBar'
import FinanceGradeCount from '../components/Finance/FinanceGradeCount'
import FinanceGraph from '../components/Finance/FinanceGraph'
import Topbar from '../components/Layout/Topbar'

const Size = styled.div`
  position: relative;
`

const Finance = () => {
  return (
    <Layout>
      <Size>
        <Topbar title='Finance' />
        <FinanceBar />
        <FinanceGradeCount />
        <FinanceGraph />
      </Size>
    </Layout>
  )
}

export default Finance
