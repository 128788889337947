import React from 'react'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'

const FeeSlip = () => {
  return (
    <Layout>
      <Topbar title='Fee Challans' />
    </Layout>
  )
}

export default FeeSlip
