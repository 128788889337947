import { Cached } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../../../config'

const FeeSlipMaker = ({ onAddItems, dueDate, onDueDateChange }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [feeInputs, setFeeInputs] = useState({
    regularFee: 11500,
    registrationFee: 5000,
    admissionFee: 15000,
    securityFee: 10000,
    annualSubscription: 7500,
  })

  const [selectedMonths, setSelectedMonths] = useState([])
  const [gradeName, setGradeName] = useState('')
  const [totalAmount, setTotalAmount] = useState(0)
  const [isPaid, setIsPaid] = useState(true)

  const [recentBarCode, setRecentBarCode] = useState('')
  const navigate = useNavigate()

  const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  useEffect(() => {
    if (['PG', 'Nursery', 'KG'].includes(gradeName)) {
      setFeeInputs({
        regularFee: 12500,
        registrationFee: 5000,
        admissionFee: 15000,
        securityFee: 10000,
        annualSubscription: 7500,
      })
    } else {
      setFeeInputs({
        regularFee: 11500,
        registrationFee: 5000,
        admissionFee: 15000,
        securityFee: 10000,
        annualSubscription: 7500,
      })
    }
  }, [gradeName])

  // Function to format selected months
  const formatSelectedMonths = () => {
    if (selectedMonths.length === 0) return 'No months selected.'
    const sortedMonths = selectedMonths.sort(
      (a, b) => monthsList.indexOf(a) - monthsList.indexOf(b)
    )
    const firstMonth = sortedMonths[0]
    const lastMonth = sortedMonths[sortedMonths.length - 1]
    return `Fee generated for months: ${firstMonth} to ${lastMonth}`
  }

  const params = useParams()

  const { data: specificStudentData, status: specificStudentStatus } = useQuery(
    'specific-student-class',
    async () => {
      const res = await axiosInstance.get(`/admission/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setGradeName(data.grade || '')
      },
    }
  )

  const { data: barcodeFetch, status: barcodStatus } = useQuery(
    'barcode-data',
    async () => {
      const res = await axiosInstance.get(`/fee/barcode`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRecentBarCode(data.barcodeValue)
      },
    }
  )

  const predefinedItems = React.useMemo(() => {
    let items = [
      { id: 1, description: 'Regular Fee', baseAmount: 11500 },
      { id: 2, description: 'Registration Fee', baseAmount: 5000 },
      { id: 3, description: 'Admission Fee', baseAmount: 15000 },
      { id: 4, description: 'Security Fee', baseAmount: 10000 },
      { id: 5, description: 'Annual Subscription', baseAmount: 7500 },
    ]

    if (
      ['PG', 'Nursery', 'KG'].some((section) => gradeName.includes(section))
    ) {
      items[0] = { ...items[0], baseAmount: 12500 }
    }

    return items
  }, [gradeName])

  useEffect(() => {
    setSelectedItems(
      [
        {
          id: 1,
          description: 'Regular Fee',
          amount: feeInputs.regularFee * selectedMonths.length,
          isChecked: true,
        },
        {
          id: 2,
          description: 'Registration Fee',
          amount: feeInputs.registrationFee,
          isChecked: true,
        },
        {
          id: 3,
          description: 'Admission Fee',
          amount: feeInputs.admissionFee,
          isChecked: true,
        },
        {
          id: 4,
          description: 'Security Fee',
          amount: feeInputs.securityFee,
          isChecked: true,
        },
        {
          id: 5,
          description: 'Annual Subscription',
          amount: feeInputs.annualSubscription,
          isChecked: true,
        },
      ].filter((item) => item.amount > 0 || item.description === 'Regular Fee')
    ) // Keep Regular Fee even if amount is 0
  }, [feeInputs, selectedMonths.length])

  const handleMonthChange = (month) => {
    const monthIndex = monthsList.indexOf(month)
    if (selectedMonths.length === 0) {
      // If no months have been selected yet, allow the first selection
      setSelectedMonths([month])
    } else {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const lastSelectedMonthIndex =
        sortedSelectedMonthIndices[sortedSelectedMonthIndices.length - 1]

      // Allow selecting the month directly following the last selected month
      if (monthIndex === lastSelectedMonthIndex + 1) {
        setSelectedMonths([...selectedMonths, month])
      } else if (monthIndex < lastSelectedMonthIndex) {
        // If selecting a previous month, check it's directly before the first selected month
        const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]
        if (monthIndex === firstSelectedMonthIndex - 1) {
          setSelectedMonths([month, ...selectedMonths])
        }
      }
      // If trying to deselect a month, only allow deselecting the last selected month
      else if (
        selectedMonths.includes(month) &&
        monthIndex === lastSelectedMonthIndex
      ) {
        const newSelectedMonths = selectedMonths.filter((m) => m !== month)
        setSelectedMonths(newSelectedMonths)
      }
    }
    updateDueDateBasedOnSelection()
  }

  const updateDueDateBasedOnSelection = () => {
    if (selectedMonths.length > 0) {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]

      // Calculate new due date as the 10th of the month following the first selected month
      const dueDate = new Date()
      dueDate.setMonth(firstSelectedMonthIndex + 1) // Set to the month after the first selected month
      dueDate.setDate(10) // Set to the 10th of the month

      if (dueDate.getMonth() === 0) {
        // This means the month has wrapped to January
        dueDate.setFullYear(dueDate.getFullYear() + 1)
      }

      // Update due date in component state
      const dueDateString = dueDate.toLocaleDateString('en-CA') // 'en-CA' ensures the format is 'yyyy-mm-dd'
      onDueDateChange({ target: { value: dueDateString } })
    } else {
      // Handle case where no months are selected (e.g., set due date to empty or to a default value)
      onDueDateChange({ target: { value: '' } })
    }
  }

  useEffect(() => {
    updateDueDateBasedOnSelection()
  }, [selectedMonths]) // This ensures the function is called after selectedMonths has been updated.

  useEffect(() => {
    const total = selectedItems.reduce((acc, item) => {
      if (item.isChecked) {
        return acc + item.amount
      }
      return acc
    }, 0)

    setTotalAmount(total.toLocaleString('en-IN'))
  }, [selectedItems])

  const handleAddItems = async () => {
    const items = [
      {
        id: 1,
        description: 'Regular Fee',
        baseAmount: feeInputs.regularFee,
        total: feeInputs.regularFee * selectedMonths.length,
      },
      {
        id: 2,
        description: 'Registration Fee',
        baseAmount: feeInputs.registrationFee,
        total: feeInputs.registrationFee,
      },
      {
        id: 3,
        description: 'Admission Fee',
        baseAmount: feeInputs.admissionFee,
        total: feeInputs.admissionFee,
      },
      {
        id: 4,
        description: 'Security Fee',
        baseAmount: feeInputs.securityFee,
        total: feeInputs.securityFee,
      },
      {
        id: 5,
        description: 'Annual Subscription',
        baseAmount: feeInputs.annualSubscription,
        total: feeInputs.annualSubscription,
      },
    ].filter((item) => item.total > 0) // Ensure items with zero total aren't included

    const monthRange = formatSelectedMonths().replace(
      'Fee generated for months: ',
      ''
    )
    const totalAmount = items.reduce((acc, item) => acc + item.total, 0)
    const incrementedBarcode = (parseInt(recentBarCode, 10) + 1)
      .toString()
      .padStart(recentBarCode.length, '0')

    const data = {
      admissionId: params.id,
      items,
      fromAndTo: monthRange,
      monthsPaid: selectedMonths,
      dueDate: new Date(dueDate),
      barcodeValue: incrementedBarcode,
      feeType: 'Admission',
      totalAmount,
      isPaid,
      paymentDate: isPaid ? new Date() : null,
    }

    try {
      await axiosInstance.post('fee/feeslips/', data)
      navigate(`/students/all-admissions/fee-challan/${params.id}`)
      // Handle success (e.g., show a success message or update the UI)
    } catch (error) {
      console.error('POST request failed:', error)
      // Handle error (e.g., show an error message)
    }
  }

  const handleItemCheck = (id) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    )
  }
  const handleFeeInputChange = (feeType, value) => {
    setFeeInputs((prev) => ({
      ...prev,
      [feeType]: Number(value),
    }))
  }

  return (
    <Box
      sx={{
        p: 3,
        border: '1px solid #ddd',
        borderRadius: 2,
        bgcolor: '#fafafa',
        boxShadow: '0px 0px 8px rgba(0,0,0,0.1)',
        maxWidth: '60vw',
        margin: 'auto',
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant='h6' sx={{ mb: 2 }}>
        Fee Slip Generator
      </Typography>
      <TextField
        id='due-date'
        label='Due Date'
        type='date'
        value={dueDate}
        onChange={(e) => onDueDateChange(e)}
        sx={{ mb: 3, width: '100%' }}
        InputLabelProps={{ shrink: true }}
      />

      <Box sx={{ mb: 3 }}>
        <TextField
          label='Regular Fee'
          type='number'
          value={feeInputs.regularFee}
          onChange={(e) => handleFeeInputChange('regularFee', e.target.value)}
          sx={{ width: '100%', mb: 1 }}
        />
        <TextField
          label='Registration Fee'
          type='number'
          value={feeInputs.registrationFee}
          onChange={(e) =>
            handleFeeInputChange('registrationFee', e.target.value)
          }
          sx={{ width: '100%', mb: 1 }}
        />
        <TextField
          label='Admission Fee'
          type='number'
          value={feeInputs.admissionFee}
          onChange={(e) => handleFeeInputChange('admissionFee', e.target.value)}
          sx={{ width: '100%', mb: 1 }}
        />
        <TextField
          label='Security Fee'
          type='number'
          value={feeInputs.securityFee}
          onChange={(e) => handleFeeInputChange('securityFee', e.target.value)}
          sx={{ width: '100%', mb: 1 }}
        />
        <TextField
          label='Annual Subscription'
          type='number'
          value={feeInputs.annualSubscription}
          onChange={(e) =>
            handleFeeInputChange('annualSubscription', e.target.value)
          }
          sx={{ width: '100%', mb: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
        {monthsList.map((month, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedMonths.includes(month)}
                onChange={() => handleMonthChange(month)}
                color='primary'
              />
            }
            label={month}
          />
        ))}
      </Box>
      <Typography variant='subtitle1' sx={{ mb: 2 }}>
        {formatSelectedMonths()}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant='subtitle1' sx={{ marginRight: 2 }}>
          Payment Status:
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPaid}
              onChange={(e) => setIsPaid(e.target.checked)}
              color='primary'
            />
          }
          label='Paid'
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Amount ($)</TableCell>
              <TableCell align='right'>Include</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell component='th' scope='row'>
                  {item.description}
                </TableCell>
                <TableCell align='right'>{item.amount}</TableCell>
                <TableCell align='right'>
                  <Checkbox
                    checked={item.isChecked}
                    onChange={() => handleItemCheck(item.id)}
                    color='primary'
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell align='right'>{totalAmount}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Button
        variant='contained'
        color='secondary'
        onClick={handleAddItems}
        sx={{ mt: 3, alignSelf: 'center' }}
      >
        <Cached style={{ marginRight: '5px' }} /> Confirm Payment
      </Button>
    </Box>
  )
}

export default FeeSlipMaker
