import {
  AccountBalanceWallet,
  AttachMoney,
  LocalAtm,
  MonetizationOn,
  Money,
  MoneyOffCsred,
} from '@mui/icons-material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import Loader from '../../Loader/Loader'
import { axiosInstance } from '../../config'

const Wrap = styled.div`
  padding: 1rem;
`
const BoxWrap = styled.div`
  min-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`
const Box = styled.div`
  width: 25%;
  min-height: 130px;
  background-color: #1902e688;
  border-radius: 10px;
  padding-left: 0.5rem;
  margin: 0.5rem 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  border: 1px dashed #1559ecf7;
`

const BoxLeft = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const BoxRight = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`
const BoxSplit = styled.div`
  width: 100%;
  font-weight: 300;
  &.strong {
    font-weight: 800;
    font-size: 1.7rem;
    margin-bottom: 10px;
  }
`
const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`
const FinanceBar = () => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [summerTotal, setSummerTotal] = useState(0)
  const { data, status } = useQuery(
    'finance-total',
    async () => {
      const res = await axiosInstance.get(`/fee/total-paid-fee-amount`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setTotalAmount(data.totalAmount || 0)
      },
    }
  )
  useQuery(
    'summer-total',
    async () => {
      const res = await axiosInstance.get(`/summerCamp/money`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setSummerTotal(data.totalPaidAmount || 0)
      },
    }
  )

  const formatAmount = (amount) => amount.toLocaleString('en-IN')

  if (status === 'loading') {
    return (
      <LoaderWrap>
        <Loader msg='Loading Finance Dashboard...' />
      </LoaderWrap>
    )
  }
  return (
    <Wrap>
      <BoxWrap>
        <Box>
          <BoxLeft>
            <AccountBalanceWallet style={{ fontSize: '3.5rem' }} />
          </BoxLeft>
          <BoxRight>
            <BoxSplit className='strong'>{formatAmount(totalAmount)}</BoxSplit>
            <BoxSplit>Total Amount</BoxSplit>
          </BoxRight>
        </Box>
        <Box>
          <BoxLeft>
            <MonetizationOn style={{ fontSize: '3.5rem' }} />
          </BoxLeft>
          <BoxRight>
            <BoxSplit className='strong'>{formatAmount(totalAmount)}</BoxSplit>
            <BoxSplit>Paid Fee</BoxSplit>
          </BoxRight>
        </Box>
        <Box>
          <BoxLeft>
            <AttachMoney style={{ fontSize: '3.5rem' }} />
          </BoxLeft>
          <BoxRight>
            <BoxSplit className='strong'>{formatAmount(summerTotal)}</BoxSplit>
            <BoxSplit>Summer Camp Fee</BoxSplit>
          </BoxRight>
        </Box>
        <Box>
          <BoxLeft>
            <MoneyOffCsred style={{ fontSize: '3.5rem' }} />
          </BoxLeft>
          <BoxRight>
            <BoxSplit className='strong'>0</BoxSplit>
            <BoxSplit>Unpaid Fee</BoxSplit>
          </BoxRight>
        </Box>
      </BoxWrap>
    </Wrap>
  )
}

export default FinanceBar
