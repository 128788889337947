import React from 'react'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import SummerDataTable from '../../../components/Students/SummerCamp/SummerDataTable'

const SummerCamp = () => {
  return (
    <Layout>
      <Topbar title='Summer Camp' />
      <SummerDataTable />
    </Layout>
  )
}

export default SummerCamp
