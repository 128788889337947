import React from 'react'
import { useSelector } from 'react-redux'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import Activity from './pages/Activity'
import Chat from './pages/Chat'
import Class from './pages/Class'
import Dashboard from './pages/Dashboard'
import Event from './pages/Event'
import Fee from './pages/Fee'
import Finance from './pages/Finance'
import Login from './pages/Login'
import Publish from './pages/Publish'
import EditStudent from './pages/Students/Admission/EditStudent'
import FeeSlip from './pages/Students/Admission/Fee/FeeSlip'
import FeeSlipDetails from './pages/Students/Admission/Fee/FeeSlipDetails'
import Invoice from './pages/Students/Admission/Fee/Invoice'
import ViewDeletedInvoices from './pages/Students/Admission/Fee/ViewDeletedInvoices'
import ViewFeeHistory from './pages/Students/Admission/Fee/ViewFeeHistory'
import NewAdmission from './pages/Students/Admission/NewAdmission'
import StudentAdmissions from './pages/Students/Admission/StudentAdmissions'
import ViewDeletedStudents from './pages/Students/Admission/ViewDeletedStudents'
import ViewStudent from './pages/Students/Admission/ViewStudent'
import EditEnrolled from './pages/Students/Enrolled/EditEnrolled'
import Enrolled from './pages/Students/Enrolled/Enrolled'
import EnrolledFeeHistory from './pages/Students/Enrolled/Fee/EnrolledFeeHistory'
import EnrolledInvoice from './pages/Students/Enrolled/Fee/EnrolledInvoice'
import FeePage from './pages/Students/Enrolled/Fee/FeePage'
import ViewDeletedEnrolled from './pages/Students/Enrolled/ViewDeletedEnrolled'
import ViewEnrolled from './pages/Students/Enrolled/ViewEnrolled'
import Students from './pages/Students/Students'
import CamperInvoice from './pages/Students/SummerCamp/CamperInvoice'
import EditCamper from './pages/Students/SummerCamp/EditCamper'
import NewCamper from './pages/Students/SummerCamp/NewCamper'
import SummerCamp from './pages/Students/SummerCamp/SummerCamp'
import ViewCamper from './pages/Students/SummerCamp/ViewCamper'
import Subjects from './pages/Subjects'
import Teachers from './pages/Teacher'
import AddTeacher from './pages/Teacher/AddTeacher'
import EditTeacher from './pages/Teacher/EditTeacher'
import ViewTeacher from './pages/Teacher/ViewTeacher'
import User from './pages/User'

const ProtectedRoute = () => {
  const { currentUser } = useSelector((state) => state.user)
  return currentUser ? <Outlet /> : <Navigate to='/login' />
}

const App = () => {
  const { currentUser } = useSelector((state) => state.user)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/login'
          element={
            !currentUser ? <Login /> : <Navigate replace to='/dashboard' />
          }
        />
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<Navigate to='/dashboard' />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='students' element={<Outlet />}>
            <Route index element={<Students />} />
            <Route path='all-admissions' element={<Outlet />}>
              <Route index element={<StudentAdmissions />} />
              <Route path='new' element={<NewAdmission />} />
              <Route path='recent' element={<ViewDeletedStudents />} />
              <Route path='view/:id' element={<ViewStudent />} />
              <Route path='edit/:id' element={<EditStudent />} />
              <Route path='fee-challan' element={<Outlet />}>
                <Route index element={<FeeSlip />} />
                <Route path='recycle/:id' element={<ViewDeletedInvoices />} />
                <Route path=':id' element={<ViewFeeHistory />} />
                <Route path='creator/:id' element={<FeeSlipDetails />} />
              </Route>
              <Route path='invoice/:id' element={<Invoice />} />
            </Route>
            <Route path='enrolled' element={<Outlet />}>
              <Route index element={<Enrolled />} />
              <Route path='fee-history/:id' element={<EnrolledFeeHistory />} />
              <Route path='edit/:id' element={<EditEnrolled />} />
              <Route path='view/:id' element={<ViewEnrolled />} />
              <Route path='recent' element={<ViewDeletedEnrolled />} />
              <Route path='fee-maker/:id' element={<FeePage />} />
              <Route path='invoice/:id' element={<EnrolledInvoice />} />
            </Route>
            <Route path='summer-camp' element={<Outlet />}>
              <Route index element={<SummerCamp />} />
              <Route path='view/:id' element={<ViewCamper />} />
              <Route path='new' element={<NewCamper />} />
              <Route path='invoice/:id' element={<CamperInvoice />} />
              <Route path='edit/:id' element={<EditCamper />} />
            </Route>
            <Route path='fee-table' element={<Fee />} />
          </Route>
          <Route path='users' element={<User />} />
          <Route path='class' element={<Class />} />
          <Route path='subjects' element={<Subjects />} />
          <Route path='teachers' element={<Outlet />}>
            <Route index element={<Teachers />} />
            <Route path='add' element={<AddTeacher />} />
            <Route path='view/:id' element={<ViewTeacher />} />
            <Route path='edit/:id' element={<EditTeacher />} />
          </Route>
          <Route path='events' element={<Event />} />
          <Route path='publish' element={<Publish />} />
          <Route path='finance' element={<Finance />} />
          <Route path='chats' element={<Chat />} />
          <Route path='activity' element={<Activity />} />
        </Route>
        <Route path='*' element={<Navigate replace to='/login' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
