import * as yup from 'yup'

export const TeachFormScehma = yup.object().shape({
  fname: yup.string().required('Please provide first   name...'),
  lname: yup.string().required('Please provide last name...'),
  cell: yup
    .string()
    .length(11, 'Mobile number must be exactly 11 digits e.g 03123456789')
    .required('Please provide a mobile number...'),
  profileimg: yup.string(),
  department: yup.string().required('Please provide a department...'),
  gmail: yup.string().email('Please follow this format : name@example.com'),
  address: yup.string().required('Please enter an address...'),
  gender: yup.string().required('Please specify a gender...'),
})

const cnicRegex = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/

// Sibling validation schema
const siblingSchema = yup.object().shape({
  name: yup.string(),
  grade: yup.string(),
  section: yup.string(),
})

export const admissionSchema = yup.object().shape({
  name: yup.string().required('Please provide a name...'),
  grade: yup.string().required('Please specify a grade...'),
  dob: yup.date().required('Date of birth is required...'),
  gender: yup.string().required('Choose a Gender...'),
  fname: yup.string().required("Father's name is required..."),
  fcompany: yup.string(),
  fNationality: yup.string(),
  mname: yup.string().required("Mother's name is required..."),
  address: yup.string().required('Please enter an address...'),
  prevSchool: yup.string(),
  fcnic: yup
    .string()
    .matches(cnicRegex, 'Please follow this CNIC format: 12345-1213112-7'),
  fcell: yup
    .string()
    .length(11, 'Mobile number must be exactly 11 digits e.g., 03123456789'),
  femail: yup.string().email('Please follow this format: name@example.com'),
  foccupation: yup.string(),
  feducation: yup.string(),
  moccupation: yup.string(),
  mcompany: yup.string(),
  fnationality: yup.string(),

  mIsWorking: yup.string().required('Please specify working status...'),
  meducation: yup.string(),
  mjob: yup.string(),
  mcell: yup
    .string()
    .length(11, 'Mobile number must be exactly 11 digits e.g., 03123456789'),
  others: yup.string(),
  siblings: yup.object().shape({
    siblingOne: siblingSchema,
    siblingTwo: siblingSchema,
    siblingThree: siblingSchema,
    siblingFour: siblingSchema,
  }),
})
