import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'
import EnrolledHistoryDataTable from '../../../../components/Students/Enrolled/Fee/EnrolledHistoryDataTable'
import { axiosInstance } from '../../../../config'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`
const WrapBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 1rem 2rem 2rem 0rem;
  border-radius: 10px;
  border: 1px solid white;
`
const BarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
`
const BarRight = styled(BarLeft)`
  justify-content: flex-end;
  padding: 2rem;
`

const BarContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  flex-direction: column;
`
const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`
const TextBig = styled.p`
  font-size: 3rem;
  font-weight: 700;
`
const MonthsWrap = styled.div`
  display: flex;
  min-width: 1100px;
  flex: 1;
  padding-right: 20px;
  color: white;
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 10px;
`
const MonthBox = styled.div`
  flex: 1;
  margin: 7px;
  min-height: 80px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isPaid ? '#13b005c0' : '#001effae')};
`
const InfoBar = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 10px;
`
const InfoBox = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${(props) => (props.$isPaid ? '#13b005c0' : '#001effae')};
  margin-left: 10px;
`
const InfoText = styled.p`
  font-size: 0.7rem;
  margin-left: 7px;
`
const EnrolledFeeHistory = () => {
  const [stuName, setStuName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [motherName, setMotherName] = useState('')
  const [gradeName, setGradeName] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [admissionNo, setAdmissionNo] = useState('')
  const [monthsPaid, setMonthsPaid] = useState(new Set())

  const params = useParams()

  useQuery(
    'fee-history-person',
    async () => {
      const res = await axiosInstance.get(`/student/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setStuName(data.personalInfo.name || '')
        setFatherName(data.guardianInfo.guardianName.fatherName || '')
        setMotherName(data.guardianInfo.guardianName.motherName || '')
        setGradeName(data.classInfo.className || '')
        setSectionName(data.classInfo.section || '')
        setAdmissionNo(data.classInfo.admissionNumber || '')
      },
    }
  )

  useQuery(
    'en-fee-monthly-info',
    async () => {
      const res = await axiosInstance.get(`/fee/feeslips-student/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        const paidMonths = new Set(monthsPaid) // Create a new Set from the existing months
        data.forEach((feeSlip) => {
          feeSlip.monthsPaid.forEach((month) => {
            paidMonths.add(month) // Add each month to the Set
          })
        })
        setMonthsPaid(paidMonths) // Update state with the new Set
      },
    }
  )
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <Layout>
      <Topbar title='Fee History' />
      <Wrap>
        <WrapBar>
          <BarLeft>
            <BarContent>
              <Text>Admission # {admissionNo}</Text>
              <Text>Name : {stuName}</Text>
              <Text>Father's Name : {fatherName}</Text>
              <Text>Mother's Name : {motherName}</Text>
              <Text>
                Class : {gradeName}
                {'  '}
                {sectionName}
              </Text>
            </BarContent>
          </BarLeft>
          <BarRight>
            <TextBig>STUDENT HISTORY</TextBig>
          </BarRight>
        </WrapBar>
        <InfoBar>
          <InfoBox $isPaid />
          <InfoText>Paid</InfoText>
          <InfoBox />
          <InfoText>Not Paid</InfoText>
        </InfoBar>
        <MonthsWrap>
          {months.map((month) => (
            <MonthBox key={month} isPaid={monthsPaid.has(month)}>
              {month}
            </MonthBox>
          ))}
        </MonthsWrap>
        <EnrolledHistoryDataTable />
      </Wrap>
    </Layout>
  )
}

export default EnrolledFeeHistory
