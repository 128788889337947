import React from 'react'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import StudentDataTable from '../../../components/Students/Admission/StudentDataTable'

const StudentAdmissions = () => {
  return (
    <Layout>
      <Topbar title='Admissions' />
      <StudentDataTable />
    </Layout>
  )
}

export default StudentAdmissions
