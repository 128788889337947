import { Error } from '@mui/icons-material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../../../Layout'

import toast, { Toaster } from 'react-hot-toast'
import { admissionSchema } from '../../../Schemas'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'
import CamperCard from './CamperCard'

const Wrap = styled.div`
  max-width: 1200px;
  /* background-color: #133b92; */
  background-color: #086102;

  color: white;
  background-size: cover;
  margin: 4rem auto;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 4rem;
  min-height: 100%;
  margin-bottom: 2rem;
  position: relative;
  @media (max-width: 1200px) {
    max-width: 1000px;
  }
  @media (max-width: 1000px) {
    max-width: 900px;
  }
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormText = styled.span`
  font-size: 1.1rem;
  margin-right: 10px;
  font-weight: 500;
  min-width: 150px;
  @media (max-width: 900px) {
    font-size: 0.8rem;
    min-width: 100px;
    font-weight: 400;
    margin-right: 5px;
  }
`
const FormTextNew = styled(FormText)`
  margin-right: 0px;
  min-width: 100px;
`
const MiddleText = styled(FormText)`
  font-size: 1.2rem;
  text-align: left;
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const InputSetNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const InputSetTwo = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 20px;
  padding: 1.5rem 0;
  width: 98%;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`

const FieldsHeading = styled.p`
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0.5rem;
  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem;
  }
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`

const SelectCat = styled.select`
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #0b0a45;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
`
const SelectOpt = styled.option`
  /* width: 50%; */
  display: flex;
  background-color: #0b0a45;

  justify-content: center;
  align-items: center;
`
const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 800;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #ffffff;
  color: green;
`
const NextBtn = styled(SubmitBtn)`
  background: #ffee00;
  color: #000000;
  border: none;
`
const PrevBtn = styled(NextBtn)`
  background: #ff0000;
  border: none;
  color: white;
`

const FormInput = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`
const FormInputNew = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`
const SplitBox = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: green;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
`
const ErrMsg = styled.p`
  font-size: 0.8rem;
  color: #e8ff3b;
  padding: 0.5rem;
  text-align: start;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const SelectedSports = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
`

const SportChip = styled.div`
  padding: 8px 12px;
  background-color: #0b0a45;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;
`

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
`

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
`

const CheckboxLabel = styled.label`
  margin: 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  margin-right: 10px;
`
const NewCamper = () => {
  // all the states here
  const [showCard, setShowCard] = useState(false)
  const [selectedSports, setSelectedSports] = useState([])
  const [selectedClubs, setSelectedClubs] = useState([])

  const handleClubChange = (event) => {
    const club = event.target.name
    if (event.target.checked) {
      setSelectedClubs((prev) => [...prev, club])
    } else {
      setSelectedClubs((prev) => prev.filter((c) => c !== club))
    }
  }

  // all funcs

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      // If the date is not valid, return an empty string or handle it accordingly
      return ''
    }
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const GradeOptions = [
    'Choose',
    'PG',
    'Nursery',
    'KG',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
  ]
  const GenderOption = ['Choose', 'Male', 'Female', 'Prefer Not To Say']
  const SportsOptions = [
    'Cricket',
    'Football',
    'Hockey',
    'Badminton',
    'Table Tennis',
    'Basketball',
    'Volleyball',
    'Squash',
  ]
  const ClubsOptions = ['Riding', 'Swimming', 'Archery', 'Shooting']

  const handleAdmissions = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosInstance.post('summerCamp/', {
        classInfo: {
          className: values.grade,
          section: '',
        },
        personalInfo: {
          name: values.name,
          prevSchool: values.prevSchool,
          dateOfBirth: values.dob,
          gender: values.gender,
        },
        guardianInfo: {
          guardianName: {
            motherName: values.mname,
            fatherName: values.fname,
          },
          guardianOccupation: {
            fatherOcc: values.foccupation,
          },
          guardianContact: {
            fatherCell: values.fcell,
            motherCell: values.mcell,
            fatherCNIC: values.fcnic,
          },
        },
        interestIn: {
          sports: selectedSports,
          clubs: selectedClubs,
        },
        isDeleted: false,
        isPaid: false,
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Form Submitted Successfully', {
          duration: 5000,
        })
        handleReset()
      } else {
        toast.error('Submission Failed. Please try again!', {
          duration: 5000,
        })
      }
    } catch (err) {
      toast.error(
        'An error occurred. Please check your network and try again.',
        {
          duration: 5000,
        }
      )
    }
  }
  // handling forms data
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      prevSchool: '',
      dob: '',
      grade: '',
      gender: '',
      fname: '',
      fcnic: '',
      fcell: '',
      foccupation: '',
      mname: '',
      mcell: '',
      sportsInterested: [],
    },
    validationSchema: admissionSchema,
    handleSubmit: handleAdmissions,
  })

  const handleSelectSport = (event) => {
    const sport = event.target.value
    if (sport) {
      setSelectedSports((prev) => [...prev, sport])
    }
  }

  const handleRemoveSport = (sport) => {
    setSelectedSports((prev) => prev.filter((s) => s !== sport))
  }

  const availableSports = SportsOptions.filter(
    (sport) => !selectedSports.includes(sport)
  )

  const handleReset = () => {
    resetForm()
    setSelectedSports([])
    setSelectedClubs([])
  }

  return (
    <Layout>
      <Toaster />

      <Topbar title='Admissions / Add' />
      <>
        <Wrap id='booking-form-wrap'>
          <>
            <FieldsHeading>Admission Form (SUMMER CAMP 2024)</FieldsHeading>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Student's Name: </FormText>
                  <FormInput
                    placeholder='Enter Name...'
                    type='text'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='name'
                  />
                </InputSet>
                {errors.name && touched.name && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.name}
                  </ErrMsg>
                )}
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Previous School: </FormText>
                  <FormInput
                    placeholder='Enter Previous School...'
                    type='text'
                    value={values.prevSchool}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='prevSchool'
                  />
                </InputSet>
                {errors.prevSchool && touched.prevSchool && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.prevSchool}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>

            <InputSetTwo>
              <FormText>Class: </FormText>
              <SelectCat
                name='grade'
                value={values.grade}
                onChange={handleChange}
                onBlur={handleBlur}
                id='grade'
              >
                {GradeOptions.map((category) => (
                  <SelectOpt key={category} value={category}>
                    {category}
                  </SelectOpt>
                ))}
              </SelectCat>
            </InputSetTwo>
            {errors.grade && touched.grade && (
              <ErrMsg>
                <Error style={{ marginRight: '5px' }} /> {errors.grade}
              </ErrMsg>
            )}
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>DOB: </FormText>
                  <FormInput
                    type='date'
                    value={formatDate(values.dob)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='dob'
                  />
                </InputSet>
                {errors.dob && touched.dob && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.dob}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSetTwo>
                  <FormText>Gender: </FormText>
                  <SelectCat
                    name='gender'
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {GenderOption.map((category) => (
                      <SelectOpt key={category} value={category}>
                        {category}
                      </SelectOpt>
                    ))}
                  </SelectCat>
                </InputSetTwo>
                {errors.gender && touched.gender && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.gender}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>

            <SplitBox>Father's Profile</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Name: </FormText>
                  <FormInput
                    placeholder='Enter Father Name...'
                    type='text'
                    value={values.fname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='fname'
                  />
                </InputSet>
                {errors.fname && touched.fname && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.fname}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> CNIC: </FormText>
                  <FormInput
                    placeholder='Enter CNIC ...'
                    type='text'
                    value={values.fcnic}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='fcnic'
                  />
                </InputSet>
                {errors.fcnic && touched.fcnic && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.fcnic}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Cell#: </FormText>
                  <FormInput
                    placeholder='Enter Cell#...'
                    type='text'
                    value={values.fcell}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='fcell'
                  />
                </InputSet>
                {errors.fcell && touched.fcell && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.fcell}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Occupation: </FormText>
                  <FormInput
                    placeholder='Enter Fathers occupation...'
                    type='text'
                    value={values.foccupation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='foccupation'
                  />
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Mother's Profile</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Name: </FormText>
                  <FormInput
                    placeholder='Enter Mothers Name...'
                    type='text'
                    value={values.mname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='mname'
                  />
                </InputSet>
                {errors.mname && touched.mname && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.mname}
                  </ErrMsg>
                )}
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Cell#: </FormText>
                  <FormInput
                    placeholder='Enter Cell#..'
                    type='text'
                    value={values.mcell}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='mcell'
                  />
                </InputSet>
                {errors.mcell && touched.mcell && (
                  <ErrMsg>
                    <Error style={{ marginRight: '5px' }} /> {errors.mcell}
                  </ErrMsg>
                )}
              </ErrorWrap>
            </InputWrap>
          </>
          <>
            <SplitBox>Sports & Club Preferences</SplitBox>
            <InputWrap>
              <InputSetTwo>
                <FormText>Sports:</FormText>
                <SelectCat
                  name='sportsInterested'
                  onChange={handleSelectSport}
                  value=''
                >
                  <SelectOpt value=''>Choose</SelectOpt>
                  {availableSports.map((option) => (
                    <SelectOpt key={option} value={option}>
                      {option}
                    </SelectOpt>
                  ))}
                </SelectCat>
              </InputSetTwo>
            </InputWrap>
            <InputSet>
              <SelectedSports>
                {selectedSports.map((sport) => (
                  <SportChip key={sport}>
                    {sport}
                    <RemoveButton onClick={() => handleRemoveSport(sport)}>
                      &times;
                    </RemoveButton>
                  </SportChip>
                ))}
              </SelectedSports>
            </InputSet>
            <InputWrap>
              <InputSet>
                <FormText>Clubs:</FormText>
                <CheckboxContainer>
                  {ClubsOptions.map((club) => (
                    <CheckboxLabel key={club}>
                      <Checkbox
                        type='checkbox'
                        name={club}
                        checked={selectedClubs.includes(club)}
                        onChange={handleClubChange}
                      />
                      {club}
                    </CheckboxLabel>
                  ))}
                </CheckboxContainer>
              </InputSet>
            </InputWrap>

            <SubSet>
              <SubmitBtn
                style={{ marginLeft: '10px' }}
                onClick={handleAdmissions}
              >
                Submit
              </SubmitBtn>
            </SubSet>
          </>
        </Wrap>
      </>
    </Layout>
  )
}

export default NewCamper
