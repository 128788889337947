import React from 'react'
import Layout from '../Layout'
import FeeDataTable from '../components/Fee/FeeDataTable'
import Topbar from '../components/Layout/Topbar'
const Fee = () => {
  return (
    <Layout>
      <Topbar title='Fee Table' />
      <FeeDataTable />
    </Layout>
  )
}

export default Fee
