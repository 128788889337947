import {
  Delete,
  Edit,
  KeyboardBackspace,
  Visibility,
} from '@mui/icons-material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../Layout'
import Loader from '../../../Loader/Loader'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config.js'

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 8px; */
`

const FormText = styled.span`
  font-weight: 700;
  min-width: 110px;
`

const FormContent = styled.p`
  font-weight: 600;
  width: 100%;
  border-bottom: 0.5px solid #00000096;
  padding: 10px 0;
  min-height: 18px;
  color: #000000;
  outline: none;
  background: transparent;
  padding: 0.3rem;
  /* border-radius: 5px; */
  min-width: 200px;
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: capitalize;
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0.2rem 0;
  padding: 0 0.4rem;
`

const FieldsHeading = styled.p`
  font-size: 1rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
  color: #000000;
  padding: 0.3rem;
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 17px;
  border-radius: 300px;
  color: white;
  margin-left: 5px;
  margin-top: 5px;
  min-width: 150px;
  &.green {
    background-color: green;
  }
  &.blue {
    background-color: blue;
  }
  &.red {
    background-color: red;
  }
  &.darkGreen {
    background-color: #003700;
  }
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 7, 35, 0.829); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const LogoImg = styled.img`
  width: 70px;
  min-width: 40px;
`
const LogoInfo = styled.p`
  font-size: 10px;
  text-transform: lowercase;
  font-weight: 300;
`
const SplitBox = styled.div`
  width: 100%;
  font-weight: 800;
  letter-spacing: 2px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  padding: 0.5rem;
  margin: 10px 0;
  margin-top: 12px;
  border: 1px solid black;
`
const A4Wrap = styled.div`
  width: 8.27in; // Width of A4 paper
  min-height: 11.69in; // Height of A4 paper
  padding: 1rem; // Padding around the content
  margin: auto; // Center the wrap on the page
  background-color: #ffffff;
  color: #000000;
  position: relative;
`

const PunchLine = styled.p`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 4rem 0;
  font-weight: 300;
`
const SignatureBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  margin-top: 4rem;
`
const StampWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const SignatureText = styled.p`
  font-size: 1rem;
  font-weight: 500;
`
const SignatureMini = styled.p`
  font-weight: 300;
  font-size: 0.4rem;
  text-align: center;
  margin-top: 5px;
`
const StampImg = styled.img`
  max-width: 100px;
  margin-top: 10px;
`
const ViewStudent = () => {
  // all the states here
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState('')
  const [prevSchool, setPrevSchool] = useState('')
  const [sNationality, setSNationality] = useState('')
  const [dob, setDob] = useState('')
  const [selectedSports, setSelectedSports] = useState([])
  const [selectedClubs, setSelectedClubs] = useState([])
  const [grade, setGrade] = useState('')
  const [gender, setGender] = useState('')
  const [fname, setFname] = useState('')
  const [fcnic, setFcninc] = useState('')
  const [femail, setFemail] = useState('')
  const [fNationality, setFNationality] = useState('')
  const [fcell, setFcell] = useState('')
  const [foccupation, setFoccupation] = useState('')
  const [mname, setMname] = useState('')
  const [moccupation, setMoccupation] = useState('')
  const [mcell, setMcell] = useState('')
  const [fcompany, setFcompany] = useState('')
  const [mcompany, setMcompany] = useState('')
  const [fLang, setFLang] = useState('')
  const [oLang, setOLang] = useState('')
  const [address, setAddress] = useState('')

  const [siblings, setSiblings] = useState({
    siblingOne: { name: '', grade: '', section: '' },
    siblingTwo: { name: '', grade: '', section: '' },
    siblingThree: { name: '', grade: '', section: '' },
    siblingFour: { name: '', grade: '', section: '' },
  })

  const [medicalHistory, setMedicalHistory] = useState({
    allergies: '',
    asthma: '',
    speechDefect: '',
    visionProblem: '',
    hearingProblem: '',
    learningProblem: '',
    compulsiveDisorder: '',
    meditation: '',
    otherCondition: '',
  })

  const navigate = useNavigate()

  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.put(`/admission/delete-by-id/${params.id}`)
      navigate('/students/all-admissions')
    } catch (err) {
      console.log(err)
    }
  }

  // get request data
  useQuery(
    'specific-studentt',
    async () => {
      const res = await axiosInstance.get(`/admission/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setName(data.name || '')
        setPrevSchool(data.prevSchool || '')
        setDob(data.dob || '')
        setGrade(data.grade || '')
        setGender(data.gender || '')
        setSelectedSports(data.selectedSports || [])
        setSelectedClubs(data.selectedClubs || [])
        setSNationality(data.sNationality || '')
        setFname(data.fname || '')
        setFcninc(data.fcnic || '')
        setFemail(data.femail || '')
        setFcell(data.fcell || '')
        setFNationality(data.fnationality || '')
        setFoccupation(data.foccupation || '')
        setFcompany(data.fcompany || '')
        setMname(data.mname || '')
        setMoccupation(data.moccupation || '')
        setMcompany(data.mcompany || '')
        setMcell(data.mcell || '')
        setAddress(data.address || '')
        setFLang(data.flang || '')
        setOLang(data.olang || '')
        setSiblings(data.siblings || [])
        setMedicalHistory(data.medicalHistory || [])
        setIsLoading(false)
      },
    }
  )

  return (
    <>
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            ${A4Wrap}, ${A4Wrap} * {
              visibility: visible;
            }
            ${A4Wrap} {
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              padding: 0px; // Reduced padding in print mode
              width: 100%;
              height: 100%;
            }
            .SubSet, .SubSet * { 
              display: none; 
            }
          }
        `}
      </style>

      <Layout>
        <Topbar title='Admissions / Print' />
        <form>
          <A4Wrap id='booking-form-wrap'>
            <LogoWrap>
              <LogoImg src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logobw.png?raw=true' />
              <FieldsHeading>The Orion School </FieldsHeading>
              <LogoInfo>www.theorionschool.com</LogoInfo>
              <LogoInfo>+92 307 7879977</LogoInfo>
              <LogoInfo>help@orionedu.net</LogoInfo>
            </LogoWrap>
            <SplitBox>Admission Form</SplitBox>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Student's Name: </FormText>
                  <FormContent>{name}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Student's Gender: </FormText>
                  <FormContent>{gender}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Class: </FormText>
                  <FormContent>{grade}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>DOB: </FormText>
                  <FormContent>{formatDate(dob)}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Previous School:: </FormText>
                  <FormContent>{prevSchool}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Nationality: </FormText>
                  <FormContent>{sNationality}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Father's Profile</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Name: </FormText>
                  <FormContent>{fname}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>CNIC: </FormText>
                  <FormContent>{fcnic}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Cell#: </FormText>
                  <FormContent>{fcell}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Company: </FormText>
                  <FormContent>{fcompany}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Occupation: </FormText>
                  <FormContent>{foccupation}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Email: </FormText>
                  <FormContent>{femail}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Nationality: </FormText>
                  <FormContent>{fNationality}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox> Mother's Profile</SplitBox>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Name: </FormText>
                  <FormContent>{mname}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Cell#: </FormText>
                  <FormContent>{mcell}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Occupation: </FormText>
                  <FormContent>{moccupation}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Company#: </FormText>
                  <FormContent>{mcompany}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Other Information</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Address: </FormText>
                  <FormContent>{address}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>First Language: </FormText>
                  <FormContent>{fLang}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Any Other: </FormText>
                  <FormContent>{oLang}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Sports & Club Preferences</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Sports Interested In </FormText>
                  <FormContent>{selectedSports.join(' ,')}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Clubs Interested In </FormText>
                  <FormContent>{selectedClubs.join(' ,')}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Siblings Information</SplitBox>
            {Object.entries(siblings).length > 0
              ? Object.entries(siblings).map(([key, sibling], index) => (
                  <InputWrap key={key}>
                    <ErrorWrap>
                      <InputSet>
                        <FormText>Sibling ({index + 1}): </FormText>
                        <FormContent>
                          {sibling.name} - {sibling.grade} - {sibling.section}
                        </FormContent>
                      </InputSet>
                    </ErrorWrap>
                  </InputWrap>
                ))
              : Array.from({ length: 4 }).map((_, index) => (
                  <InputWrap key={index}>
                    <ErrorWrap>
                      <InputSet>
                        <FormText>Sibling ({index + 1}): </FormText>
                        <FormContent>N/A - N/A - N/A</FormContent>
                      </InputSet>
                    </ErrorWrap>
                  </InputWrap>
                ))}

            <SplitBox style={{ marginTop: '5rem' }}>Medical History</SplitBox>

            {Object.entries(medicalHistory).map(([key, value]) => (
              <InputWrap key={key}>
                <ErrorWrap>
                  <InputSet>
                    <FormText>
                      {key
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .charAt(0)
                        .toUpperCase() +
                        key
                          .split(/(?=[A-Z])/)
                          .join(' ')
                          .slice(1)}
                      :{' '}
                    </FormText>
                    <FormContent>{value || 'None'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
            ))}

            <SignatureBox>
              <StampWrap>
                <SignatureText>Admission Officer</SignatureText>
                <StampImg src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/stamp.png?raw=true' />
              </StampWrap>
              <SignatureText>
                Parent's Signature <br />{' '}
                <SignatureMini>
                  ({fname} or {mname})
                </SignatureMini>
              </SignatureText>
            </SignatureBox>
            <LogoWrap>
              <PunchLine>
                You're officially in...
                <br />
                Let the journey begin! Thrilled to have you with us.
              </PunchLine>
              <LogoInfo>www.theorionschool.com</LogoInfo>
            </LogoWrap>

            {isLoading && (
              <CardBase>
                <Loader msg='Loading Student Profile Card...' />
              </CardBase>
            )}
          </A4Wrap>
          <SubSet>
            <SubmitBtn className='green' onClick={goBack}>
              <KeyboardBackspace style={{ marginRight: '5px' }} />
              Back
            </SubmitBtn>
            <Link to={`/students/all-admissions/edit/${params.id}`}>
              <SubmitBtn className='blue'>
                <Edit style={{ marginRight: '5px' }} />
                Edit
              </SubmitBtn>
            </Link>
            <SubmitBtn className='red' onClick={handleDelete}>
              <Delete style={{ marginRight: '5px' }} />
              Delete
            </SubmitBtn>

            <SubmitBtn className='darkGreen' onClick={() => window.print()}>
              <Visibility style={{ marginRight: '5px' }} />
              Print
            </SubmitBtn>
          </SubSet>
        </form>
      </Layout>
    </>
  )
}

export default ViewStudent
