import React from 'react'

import LoginCard from '../components/Login/LoginCard'

const Login = () => {
  return (
    <>
      <LoginCard />
    </>
  )
}

export default Login
