import { Restore } from '@mui/icons-material'
import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Oval } from 'react-loader-spinner'
import { useQuery } from 'react-query'
import styled, { keyframes } from 'styled-components'
import { axiosInstance } from '../../../config'

const CardContainer = styled.div`
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 290px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #fff;
`

const DismissButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: black;
  border: 2px solid #d1d5db;
  font-size: 1rem;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: 0.3s ease;

  &:hover {
    background-color: #ee0d0d;
    border: 2px solid #ee0d0d;
    color: #fff;
  }
`

const Header = styled.div`
  padding: 1.25rem 1rem 1rem 1rem;
`

const Image = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #e7e2fe;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  animation: animate 0.6s linear alternate-reverse infinite;
  transition: 0.6s ease;

  svg {
    color: #420afa;
    width: 2rem;
    height: 2rem;
  }
`

const Content = styled.div`
  margin-top: 0.75rem;
  text-align: center;
`

const Title = styled.span`
  color: #10066e;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const Message = styled.p`
  margin-top: 0.5rem;
  color: #595b5f;
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const Actions = styled.div`
  margin: 0.75rem 1rem;
`

const HistoryButton = styled.button`
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #2cb705;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
`
const UnPaidBtn = styled(HistoryButton)`
  margin-top: 10px;
  background-color: #000000;
  color: white;
`
const AmountInput = styled.input`
  width: 80%;
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
    border-color: #420afa;
    box-shadow: 0 0 0 3px rgba(66, 10, 250, 0.2);
  }
`
const TrackButton = styled.button`
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  transition: all 0.3s ease-in-out;
  background-color: red;

  &:focus {
    outline: none;
    border-color: #420afa;
    box-shadow: 0 0 0 3px rgba(66, 10, 250, 0.2);
  }
`
const animate = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
`

const ImageAnimation = styled(Image)`
  animation: ${animate} 0.6s linear alternate-reverse infinite;
`
const CampFeeCard = ({ studentKey, onClose }) => {
  const [amount, setAmount] = useState(15000) // State to hold the amount
  const [checkPaid, setCheckPaid] = useState(true)
  const handleStudent = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosInstance.put(`summerCamp/${studentKey}`, {
        PaidAmount: amount,
        isPaid: true,
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Amount Paid Successfully', {
          duration: 4000,
        })
      } else {
        toast.error('Payment Failed to Process', {
          duration: 4000,
        })
      }
    } catch (err) {
      toast.error(
        'An error occurred. Please check your network and try again.',
        {
          duration: 4000,
        }
      )
    } finally {
      refetch()
      onClose()
    }
  }

  const handleUnpaid = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosInstance.put(`summerCamp/${studentKey}`, {
        PaidAmount: 0,
        isPaid: false,
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Payment rewinded, Fee is now unpaid!', {
          icon: <Restore />,
          duration: 4000,
        })
      } else {
        toast.error('Payment Failed to Process', {
          duration: 5000,
        })
      }
    } catch (err) {
      toast.error(
        'An error occurred. Please check your network and try again.',
        {
          duration: 5000,
        }
      )
    } finally {
      refetch()
      onClose()
    }
  }

  const { refetch, status } = useQuery(
    'summer-view-student',
    async () => {
      const res = await axiosInstance.get(`/summerCamp/${studentKey}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setCheckPaid(data.isPaid)
      },
    }
  )

  if (status === 'loading') {
    return (
      <Oval
        visible={true}
        height='40'
        width='40'
        strokeWidth='5'
        color='#a80707'
        secondaryColor='#e80000'
        ariaLabel='oval-loading'
      />
    )
  }

  return (
    <CardContainer>
      <Toaster />

      <DismissButton type='button' onClick={onClose}>
        ×
      </DismissButton>
      <Header>
        <ImageAnimation>
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M20 7L9.00004 18L3.99994 13'
              stroke='#000000'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            ></path>
          </svg>
        </ImageAnimation>
        {!checkPaid && (
          <Content>
            <Title>Payment Card</Title>
            <Message>
              Enter Amount
              <AmountInput
                type='number'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Message>
          </Content>
        )}
        {!checkPaid && (
          <Actions>
            <HistoryButton type='button' onClick={handleStudent}>
              Mark As Paid
            </HistoryButton>
            <TrackButton type='button' onClick={onClose}>
              Close
            </TrackButton>
          </Actions>
        )}
        {checkPaid && (
          <Actions>
            <UnPaidBtn type='button' onClick={handleUnpaid}>
              Mark As Not Paid
            </UnPaidBtn>
            <TrackButton type='button' onClick={onClose}>
              Close
            </TrackButton>
          </Actions>
        )}
      </Header>
    </CardContainer>
  )
}

export default CampFeeCard
