import { Cached } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../../../config'

const FeeMaker = ({ onAddItems, dueDate, onDueDateChange }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [allMonthsPaid, setAllMonthsPaid] = useState([])
  const [selectedMonths, setSelectedMonths] = useState([])
  const [gradeName, setGradeName] = useState('')
  const [regularFee, setRegularFee] = useState(11500) // Default regular fee
  const [othersFee, setOthersFee] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [isPaid, setIsPaid] = useState(true)

  const [recentBarCode, setRecentBarCode] = useState('')
  const navigate = useNavigate()

  const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  useEffect(() => {
    // Update regular fee based on grade level
    setRegularFee(['PG', 'Nursery', 'KG'].includes(gradeName) ? 12500 : 11500)
  }, [gradeName])

  // Function to format selected months
  const formatSelectedMonths = () => {
    if (selectedMonths.length === 0) return 'No months selected.'
    const sortedMonths = selectedMonths.sort(
      (a, b) => monthsList.indexOf(a) - monthsList.indexOf(b)
    )
    const firstMonth = sortedMonths[0]
    const lastMonth = sortedMonths[sortedMonths.length - 1]
    return `Fee generated for months: ${firstMonth} to ${lastMonth}`
  }

  const params = useParams()

  useQuery(
    'specific-student-class',
    async () => {
      const res = await axiosInstance.get(`/student/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setGradeName(data.classInfo.className || '')
      },
    }
  )

  useQuery(
    'barcode-data',
    async () => {
      const res = await axiosInstance.get(`/fee/barcode`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRecentBarCode(data.barcodeValue)
      },
    }
  )

  useQuery(
    'student-regular-fee',
    async () => {
      const res = await axiosInstance.get(`/fee/feeslips-student/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRegularFee(data[0].items[0].baseAmount)
        const combinedMonthsPaid = data.reduce((acc, current) => {
          return [...acc, ...current.monthsPaid]
        }, [])
        setAllMonthsPaid([...new Set(combinedMonthsPaid)])
      },
    }
  )

  useEffect(() => {
    const newItems = [
      {
        id: 1,
        description: 'Regular Fee',
        amount: regularFee * selectedMonths.length,
        isChecked: true,
      },
      {
        id: 2,
        description: 'Others Fee',
        amount: othersFee,
        isChecked: othersFee > 0, // Only include if othersFee is greater than 0
      },
    ].filter((item) => item.amount > 0) // Filter out items with zero amount

    setSelectedItems(newItems)
    setTotalAmount(newItems.reduce((acc, item) => acc + item.amount, 0))
  }, [regularFee, othersFee, selectedMonths.length])

  const handleMonthChange = (month) => {
    const monthIndex = monthsList.indexOf(month)
    if (selectedMonths.length === 0) {
      // If no months have been selected yet, allow the first selection
      setSelectedMonths([month])
    } else {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const lastSelectedMonthIndex =
        sortedSelectedMonthIndices[sortedSelectedMonthIndices.length - 1]

      // Allow selecting the month directly following the last selected month
      if (monthIndex === lastSelectedMonthIndex + 1) {
        setSelectedMonths([...selectedMonths, month])
      } else if (monthIndex < lastSelectedMonthIndex) {
        // If selecting a previous month, check it's directly before the first selected month
        const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]
        if (monthIndex === firstSelectedMonthIndex - 1) {
          setSelectedMonths([month, ...selectedMonths])
        }
      }
      // If trying to deselect a month, only allow deselecting the last selected month
      else if (
        selectedMonths.includes(month) &&
        monthIndex === lastSelectedMonthIndex
      ) {
        const newSelectedMonths = selectedMonths.filter((m) => m !== month)
        setSelectedMonths(newSelectedMonths)
      }
    }
    updateDueDateBasedOnSelection()
  }

  const updateDueDateBasedOnSelection = () => {
    if (selectedMonths.length > 0) {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]

      // Calculate new due date as the 10th of the month following the first selected month
      const dueDate = new Date()
      dueDate.setMonth(firstSelectedMonthIndex + 1) // Set to the month after the first selected month
      dueDate.setDate(10) // Set to the 10th of the month

      if (dueDate.getMonth() === 0) {
        // This means the month has wrapped to January
        dueDate.setFullYear(dueDate.getFullYear() + 1)
      }

      // Update due date in component state
      const dueDateString = dueDate.toLocaleDateString('en-CA') // 'en-CA' ensures the format is 'yyyy-mm-dd'
      onDueDateChange({ target: { value: dueDateString } })
    } else {
      // Handle case where no months are selected (e.g., set due date to empty or to a default value)
      onDueDateChange({ target: { value: '' } })
    }
  }

  useEffect(() => {
    updateDueDateBasedOnSelection()
  }, [selectedMonths]) // This ensures the function is called after selectedMonths has been updated.

  useEffect(() => {
    const total = selectedItems.reduce((acc, item) => {
      if (item.isChecked) {
        return acc + item.amount
      }
      return acc
    }, 0)

    setTotalAmount(total.toLocaleString('en-IN'))
  }, [selectedItems])

  const handleAddItems = async () => {
    const items = [
      {
        id: 1,
        description: 'Regular Fee',
        baseAmount: regularFee,
        total: regularFee * selectedMonths.length,
      },
      {
        id: 2,
        description: 'Other Fee',
        baseAmount: othersFee,
        total: othersFee,
      },
    ].filter((item) => item.total > 0)

    const monthRange = formatSelectedMonths().replace(
      'Fee generated for months: ',
      ''
    )
    const totalAmount = items.reduce((acc, item) => acc + item.total, 0)
    const incrementedBarcode = (parseInt(recentBarCode, 10) + 1)
      .toString()
      .padStart(recentBarCode.length, '0')

    const data = {
      studentId: params.id,
      items,
      fromAndTo: monthRange,
      monthsPaid: selectedMonths,
      dueDate: new Date(dueDate),
      barcodeValue: incrementedBarcode,
      feeType: 'Admission',
      totalAmount,
      isPaid,
      paymentDate: isPaid ? new Date() : null,
    }

    try {
      await axiosInstance.post('fee/feeslips/', data)
      navigate(`/students/enrolled/fee-history/${params.id}`)
      toast.success('Payment Received Successfully', {
        duration: 4000,
      })
    } catch (error) {
      toast.error(`Payment Error (${error})`, {
        duration: 4000,
      })
    }
  }

  const handleItemCheck = (id) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    )
  }

  const handleOthersFeeChange = (event) => {
    const value = event.target.value
    setOthersFee(value === '' ? '' : Number(value))
  }

  const handleOthersFeeBlur = () => {
    if (othersFee === '') {
      setOthersFee() // Set back to 0 when input is empty and loses focus
    }
  }
  const handleRegularFeeChange = (event) => {
    const value = event.target.value
    setRegularFee(value === '' ? '' : Number(value))
  }

  const handleRegularFeeBlur = () => {
    if (othersFee === '') {
      setRegularFee() // Set back to 0 when input is empty and loses focus
    }
  }

  return (
    <>
      <Box
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: '#def8e1',
          maxWidth: '60vw',
          margin: 'auto',
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' sx={{ mb: 2 }}>
          Billing System
        </Typography>

        <Box sx={{ mb: 3 }}>
          <TextField
            id='due-date'
            label='Due Date'
            type='date'
            value={dueDate}
            onChange={onDueDateChange}
            sx={{ mb: 3, width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Regular Fee'
            type='number'
            value={regularFee}
            onChange={handleRegularFeeChange}
            onBlur={handleRegularFeeBlur}
            sx={{ width: '100%', mb: 1 }}
          />
          <TextField
            label='Other Fee'
            type='number'
            value={othersFee}
            onChange={handleOthersFeeChange}
            onBlur={handleOthersFeeBlur}
            sx={{ width: '100%', mb: 3 }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
          {monthsList
            .filter((month) => !allMonthsPaid.includes(month))
            .map((month, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedMonths.includes(month)}
                    onChange={() => handleMonthChange(month)}
                    color='primary'
                  />
                }
                label={month}
              />
            ))}
        </Box>

        <Typography
          variant='subtitle2'
          sx={{ mt: 2, mb: 2, color: 'darkgreen' }}
        >
          Already Paid Months: {allMonthsPaid.join(', ')}
        </Typography>
        <Typography variant='subtitle1' sx={{ mb: 2 }}>
          {formatSelectedMonths()}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant='subtitle1' sx={{ marginRight: 2 }}>
            Payment Status:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPaid}
                onChange={(e) => setIsPaid(e.target.checked)}
                color='primary'
              />
            }
            label='Paid'
          />
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell align='right'>Amount (Rs.)</TableCell>
                <TableCell align='right'>Include</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component='th' scope='row'>
                    {item.description}
                  </TableCell>
                  <TableCell align='right'>{item.amount}</TableCell>
                  <TableCell align='right'>
                    <Checkbox
                      checked={item.isChecked}
                      onChange={() => handleItemCheck(item.id)}
                      color='primary'
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align='right'>{totalAmount}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Button
          variant='contained'
          onClick={handleAddItems}
          sx={{
            mt: 3,
            alignSelf: 'center',
            backgroundColor: 'primary.main', // Using theme's primary color
            color: 'white', // Ensuring text color is white for better readability
            '&:hover': {
              backgroundColor: 'primary.dark', // Darker on hover
            },
          }}
        >
          Confirm Payment
        </Button>
      </Box>
    </>
  )
}

export default FeeMaker
