import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Loader from '../../../Loader/Loader'
import { axiosInstance } from '../../../config'
const PrintButton = styled.button`
  position: absolute;
  right: 1cm;
  top: 1cm;
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }

  @media print {
    display: none;
  }
`

const PageContainer = styled.div`
  position: relative;
  width: 21cm;
  /* height: 100%; */
  height: 25.7cm;
  margin: 0 auto;
  color: #001028;
  background: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 12px;
`

const Header = styled.header`
  padding: 10px 0;
  margin-bottom: 30px;
`

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
  min-height: 130px;
`

const LogoImage = styled.img`
  width: 90px;
`

const Heading = styled.h1`
  border-top: 1px solid #5d6975;
  border-bottom: 1px solid #5d6975;
  color: #5d6975;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
  background-color: #dadcde;
  background: url('https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/dimension.png?raw=true');
`

const ProjectContainer = styled.div`
  float: left;
  min-width: 200px;
  padding: 1rem;
`

const ProjectSpan = styled.span`
  color: #5d6975;
  text-align: right;
  margin-right: 2rem;
  margin-left: 1rem;
  display: inline-block;
  font-size: 1.1em;
  text-transform: capitalize;
  font-weight: 300;
`
const ProjectSpanValue = styled(ProjectSpan)`
  color: black;
  margin-left: 0.5rem;
  font-weight: 500;
`

const CompanyContainer = styled.div`
  float: right;
  text-align: right;
  padding: 1rem;
  font-weight: 300;
  font-size: 0.9rem;
  color: #616060;
`

const InfoContainer = styled.div`
  white-space: nowrap;
`

const Main = styled.main`
  margin-bottom: 20px;
  padding-top: 8rem;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`

const TableRow = styled.tr`
  &:nth-child(2n-1) {
    td {
      background: #f5f5f5;
    }
  }
`

const TableHeader = styled.th`
  padding: 5px 20px;
  color: #5d6975;
  border-bottom: 1px solid #c1ced9;
  white-space: nowrap;
  font-weight: normal;
`

const TableCell = styled.td`
  padding: 20px;
  text-align: center;

  &.service,
  &.desc {
    text-align: center;
  }

  &.unit,
  &.qty,
  &.total {
    font-size: 1.2em;
  }

  &.grand {
    border-top: 1px solid #5d6975;
  }
`

const Footer = styled.footer`
  color: #5d6975;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #c1ced9;
  padding: 8px 0;
  text-align: center;
`
const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`
const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const WaterMark = styled.img`
  width: 150px;
  margin-left: 300px;
  margin-top: 50px;
`

const Invoice = () => {
  const params = useParams()

  const pageContentRef = useRef()

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN').format(amount)
  }

  const handlePrint = () => {
    window.print()
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const { data, status } = useQuery('student-invoice-show', async () => {
    const res = await axiosInstance.get(`/summerCamp/${params.id}`)
    return res.data
  })

  if (status === 'loading') {
    return (
      <LoaderWrap>
        <Loader msg='Loading Invoice Data...' />
      </LoaderWrap>
    )
  }
  return (
    <>
      <PageContainer ref={pageContentRef}>
        <PrintButton onClick={handlePrint}>Print this page</PrintButton>
        <Header>
          <LogoContainer>
            <LogoImage src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logobw.png?raw=true' />
          </LogoContainer>
          <Heading>SUMMER CAMP INVOICE</Heading>
          <ProjectContainer>
            <InfoContainer>
              <ProjectSpan>
                Name:{' '}
                <ProjectSpanValue>{data.personalInfo.name}</ProjectSpanValue>
              </ProjectSpan>
            </InfoContainer>
            <InfoContainer>
              <ProjectSpan>
                Guardian:{' '}
                <ProjectSpanValue>
                  {data.guardianInfo.guardianName.fatherName}
                </ProjectSpanValue>
              </ProjectSpan>
            </InfoContainer>
            <InfoContainer>
              <ProjectSpan>
                Class:{' '}
                <ProjectSpanValue>{data.classInfo.className}</ProjectSpanValue>
              </ProjectSpan>
            </InfoContainer>
            <InfoContainer>
              <ProjectSpan>
                Phone #{' '}
                <ProjectSpanValue>
                  {data.guardianInfo.guardianContact.fatherCell}
                </ProjectSpanValue>{' '}
              </ProjectSpan>
            </InfoContainer>
            <InfoContainer>
              <ProjectSpan>
                Payment Method : <ProjectSpanValue>On Campus</ProjectSpanValue>{' '}
              </ProjectSpan>
            </InfoContainer>
            <InfoContainer>
              <ProjectSpan>
                Payment Date :
                <ProjectSpanValue>
                  {' '}
                  {formatDate(data.createdAt)}
                </ProjectSpanValue>{' '}
              </ProjectSpan>
            </InfoContainer>
          </ProjectContainer>
          <CompanyContainer>
            <InfoContainer>THE ORION SCHOOL</InfoContainer>
            <InfoContainer>Sargodha</InfoContainer>
            <InfoContainer>+92 (307) 7879977</InfoContainer>
            <InfoContainer>
              <a href='mailto:company@example.com'>help@orionedu.net</a>
            </InfoContainer>
            <InfoContainer>www.theorionschool.com</InfoContainer>
          </CompanyContainer>
        </Header>

        <Main>
          <Table>
            <thead>
              <TableRow>
                <TableHeader className='service'>ID</TableHeader>
                <TableHeader className='desc'>DESCRIPTION</TableHeader>
                <TableHeader>PRICE</TableHeader>
                <TableHeader>QTY</TableHeader>
                <TableHeader>TOTAL</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <TableCell className='service'>1</TableCell>
                <TableCell className='desc'>Summer Camp Fee</TableCell>
                <TableCell className='unit'>
                  {formatAmount(data.PaidAmount)}
                </TableCell>
                <TableCell className='qty'>1</TableCell>
                <TableCell className='total'>
                  {formatAmount(data.PaidAmount)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan='4'>Total</TableCell>
                <TableCell className='total'>
                  Rs. {formatAmount(data.PaidAmount)}
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
          <ImgDiv>
            <WaterMark src='https://png.pngtree.com/png-vector/20230208/ourmid/pngtree-paid-stamp-vector-illustration-png-image_6585127.png' />
          </ImgDiv>
        </Main>

        <Footer>
          Copyrights © All Rights Reserved 2024 - THE ORION SCHOOL
        </Footer>
      </PageContainer>
    </>
  )
}

export default Invoice
