import React from 'react'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import EnrolledDataTable from '../../../components/Students/Enrolled/EnrolledDataTable'

const Enrolled = () => {
  return (
    <Layout>
      <Topbar title='Students' />
      <EnrolledDataTable />
    </Layout>
  )
}

export default Enrolled
