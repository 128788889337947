import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import { axiosInstance } from '../../config'

const Wrap = styled.div`
  min-width: 1200px;
  width: 100%;
  padding: 20px;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledSelect = styled.select`
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  background-color: #3aa933;
  color: white;
`

const Heading = styled.h1`
  color: #ffffff;
`

const monthNumbers = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}
const monthNames = Object.keys(monthNumbers) // Array of month names
const FinanceGraph = () => {
  const currentMonth = new Date().getMonth() // getMonth() returns 0 for January, 1 for February, etc.
  const [selectedMonth, setSelectedMonth] = useState(monthNames[currentMonth])

  const [admissionsData, setAdmissionsData] = useState([])

  const handleChange = (event) => {
    setSelectedMonth(event.target.value)
  }

  const { refetch } = useQuery(
    ['admissionsData', selectedMonth],
    async () => {
      const month = monthNumbers[selectedMonth]
      const year = 2024
      const res = await axiosInstance.get(
        `student/dataByMonth/${year}/${month}`
      )
      return res.data
    },
    {
      enabled: false, // Disable automatic refetch
      onSuccess: (data) => {
        const year = 2024 // Assuming year is static for simplicity
        const monthIndex = monthNumbers[selectedMonth] - 1 // Month index for Date constructor (0-based)
        const daysInMonth = new Date(year, monthIndex + 1, 0).getDate()

        // Create a template array for the full month with default count values
        const fullMonthData = Array.from(
          { length: daysInMonth },
          (_, index) => ({
            day: index + 1, // Just the day number
            students: 0,
            summerCamp: 0,
          })
        )

        // Populate the actual data received
        data.studentAdmissionsByDay.forEach((day) => {
          const dayIndex = day._id.day - 1 // Convert 1-based day to 0-based index
          fullMonthData[dayIndex].students = day.count
        })

        data.summerCampAdmissionsByDay.forEach((day) => {
          const dayIndex = day._id.day - 1 // Convert 1-based day to 0-based index
          fullMonthData[dayIndex].summerCamp = day.count
        })
        setAdmissionsData(fullMonthData)
      },
    }
  )
  useEffect(() => {
    refetch()
  }, [selectedMonth, refetch])

  return (
    <Wrap>
      <Box>
        <Heading>Daily Admissions Chart</Heading>
        <StyledSelect onChange={handleChange} value={selectedMonth}>
          {Object.keys(monthNumbers).map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </StyledSelect>
      </Box>

      <ResponsiveContainer width='100%' height={400}>
        <LineChart
          data={admissionsData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='day' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type='monotone'
            dataKey='students'
            stroke='#8884d8'
            strokeWidth={2}
          />
          <Line
            type='monotone'
            dataKey='summerCamp'
            stroke='#82ca9d'
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Wrap>
  )
}

export default FinanceGraph
