import { Add, Download, TextRotationNone } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'

import * as React from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import * as XLSX from 'xlsx'
import Loader from '../../../Loader/Loader'
import { axiosInstance } from '../../../config'
import CampFeeCard from './CampFeeCard'
// styling of tables
const Wrap = styled.div`
  height: 100%;
  width: 95%;
  border-radius: 10px;
  text-transform: capitalize;
`
const GreenBtn = styled.button`
  background-color: #058d19;
  padding: 0.5rem 0.8rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`
const RedBtn = styled.button`
  background-color: #8d0505;
  padding: 0.5rem 0.8rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-right: 15px;
`
const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`
const Drop = styled.select`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 10px;
`
const DropOption = styled.option`
  background-color: #0ea004;
  color: #ffffff;
`
const WrapBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const OptionsLabel = styled.span`
  font-size: 1rem;
  color: white;
  margin-left: 10px;
`
const LabelWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BtnSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const CardContainer = styled.div`
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 290px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #fff;
`

const DismissButton = styled.button`
  cursor: pointer;

  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: black;
  border: 2px solid #d1d5db;
  font-size: 1rem;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: 0.3s ease;

  &:hover {
    background-color: #ee0d0d;
    border: 2px solid #ee0d0d;
    color: #fff;
  }
`

const Header = styled.div`
  padding: 1.25rem 1rem 1rem 1rem;
`

const Image = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #fee2e2;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  animation: animate 0.6s linear alternate-reverse infinite;
  transition: 0.6s ease;

  svg {
    color: #420afa;
    width: 2rem;
    height: 2rem;
  }
`

const Content = styled.div`
  margin-top: 0.75rem;
  text-align: center;
`

const Title = styled.span`
  color: #6e0606;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const Message = styled.p`
  margin-top: 0.5rem;
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: capitalize;
`

const Actions = styled.div`
  margin: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const HistoryButton = styled.button`
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #b7050533;
  border: 1px solid #b70505;
  color: #b70505;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #b70505;
    color: #ffffff;
    font-weight: 700;
  }
`
const TranferAllBtn = styled(HistoryButton)`
  background-color: #a66005bd;
  color: white;
  font-weight: 500;
  border: none;
  &:hover {
    background-color: #a66005;
    color: #ffffff;
  }
`
const GenerateBtn = styled.button`
  background-color: #ddff01;
  color: #000000;
  max-width: 100px;
  font-weight: 400;
  padding: 0.7rem 0.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`
const ViewBtn = styled(GenerateBtn)`
  background-color: #19c402;
  color: white;
`
const NewBtn = styled(RedBtn)`
  background-color: #c4026a;
`
const animate = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
`

const ImageAnimation = styled(Image)`
  animation: ${animate} 0.6s linear alternate-reverse infinite;
`

const StatsInfo = styled.p`
  color: white;
  font-size: 1rem;
  margin-right: 10px;
  font-weight: 300;
`
const Strong = styled.strong`
  font-weight: 600;
`

export default function SummerDataTable({ bookingDate }) {
  const [studentKey, setStudentKey] = useState(0) // Initial state for
  const [displayTransfer, setDisplayTransfer] = useState(false)
  const navigate = useNavigate()
  const [stats, setStats] = useState({})
  const [showCard, setShowCard] = useState(false)

  const handleClose = () => {
    refetch()
    setShowCard(false)
  }

  const handleGenerateFeeSlip = (event, studentId) => {
    event.stopPropagation() // This stops the event from propagating further.
    setStudentKey(studentId)
    setShowCard(true)
  }

  const handleInvoice = (event, studentId) => {
    event.stopPropagation() // This stops the event from propagating further.
    navigate(`/students/summer-camp/invoice/${studentId}`)
  }

  // tables code
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'fatherName', headerName: ` Father's Name`, width: 160 },
    {
      field: 'fatherCell',
      headerName: ` Father's Cell`,
      width: 130,
      sortable: false,
    },
    { field: 'motherName', headerName: ` Mother's Name`, width: 125 },
    {
      field: 'motherCell',
      headerName: ` Mother's Cell`,
      width: 130,
      sortable: false,
    },
    { field: 'grade', headerName: 'Class', width: 80, sortable: false },
    // { field: 'batch', headerName: 'Batch', width: 80 },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.isPaid ? 'green' : 'red',
            backgroundColor: params.row.isPaid ? '#10d00639' : '#fd2f2f39',
            padding: '0.5rem',
            borderRadius: '10px',
          }}
        >
          {params.row.status}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 90,
      renderCell: (params) => {
        return (
          <GenerateBtn
            variant='contained'
            color='primary'
            onClick={(event) => handleGenerateFeeSlip(event, params.row._id)}
          >
            Show Fee
          </GenerateBtn>
        )
      },
    },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <ViewBtn
            variant='contained'
            color='primary'
            onClick={(event) => handleInvoice(event, params.row._id)}
          >
            View Invoice
          </ViewBtn>
        )
      },
    },
  ]

  const { data, status, refetch } = useQuery(
    'all-summercamp-forms',
    async () => {
      const res = await axiosInstance.get(`/summerCamp`)
      return res.data
    }
  )

  useQuery(
    'all-summercamp-stats',
    async () => {
      const res = await axiosInstance.get(`/summerCamp/stats`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setStats(data)
      },
    }
  )

  if (status === 'loading') {
    return (
      <LoaderWrap>
        <Loader msg='Loading Students...' />
      </LoaderWrap>
    )
  }

  let counter = 1
  const rows = data.map((item) => ({
    id: counter++,
    _id: item._id,
    feeID: item.feeID,
    name: item.personalInfo.name,
    grade: item.classInfo.className,
    fatherName: item.guardianInfo.guardianName.fatherName || 'N/A',
    fatherCell: item.guardianInfo.guardianContact.fatherCell || 'N/A',
    motherName: item.guardianInfo.guardianName.motherName || 'N/A',
    motherCell: item.guardianInfo.guardianContact.motherCell || 'N/A',
    PaidAmount: item.PaidAmount || 'N/A',
    // batch: getYearFromDate(item.createdAt),
    status: item.isPaid ? 'Paid' : 'Unpaid',
    isPaid: item.isPaid,
  }))

  const handleExportToExcel = () => {
    // Map rows with the correct header names, including class and section
    const mappedRows = rows.map((row) => ({
      ID: row.id,
      Name: row.name,
      Class: row.grade, // Include class
      FatherName: row.fatherName,
      FatherCell: row.fatherCell,
      MotherName: row.motherName,
      MotherCell: row.motherCell,
      PaidAmount: row.PaidAmount,
      // Batch: row.batch,
    }))

    // Create a worksheet with headers
    const ws = XLSX.utils.json_to_sheet(mappedRows, {
      header: Object.keys(mappedRows[0]),
    })

    const csv = XLSX.utils.sheet_to_csv(ws)
    const blob = new Blob([csv], { type: 'text/csv' })

    // Generate the current date string
    const currentDate = new Date().toISOString().split('T')[0] // Format: YYYY-MM-DD

    // Create a download link and click it with a dynamic file name
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = `summer_camp_${currentDate}.csv` // Include filters in the filename
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN').format(amount)
  }
  return (
    <>
      <Wrap>
        <WrapBtn>
          <LabelWrap>
            <StatsInfo>
              <Strong>Paid Students = </Strong>
              {stats.paidCount}
            </StatsInfo>
            <StatsInfo>
              <Strong>UnPaid Students = </Strong>
              {stats.unpaidCount}
            </StatsInfo>
            <StatsInfo>
              <Strong>Paid Amount = </Strong>Rs.
              {formatAmount(stats.totalAmount)}
            </StatsInfo>
          </LabelWrap>
          <BtnSet>
            <Link to='new' style={{ textDecoration: 'none' }}>
              <NewBtn>
                <Add style={{ marginRight: '5px' }} />
                New
              </NewBtn>
            </Link>

            <GreenBtn
              onClick={handleExportToExcel}
              variant='outlined'
              color='primary'
            >
              <Download style={{ marginRight: '5px' }} /> Save to Excel
            </GreenBtn>
          </BtnSet>
        </WrapBtn>

        {displayTransfer && (
          <CardBase>
            <CardContainer>
              <DismissButton
                type='button'
                onClick={() => setDisplayTransfer(false)}
              >
                ×
              </DismissButton>
              <Header>
                <ImageAnimation>
                  <TextRotationNone style={{ color: '#a66005' }} />
                </ImageAnimation>
                <Content>
                  <Title>Move Enrolled Students</Title>
                  <Message>
                    <br></br>By Clicking On "Confirm Transfer" <br></br>
                    <br></br>All Enrolled Students will be permanently moved to
                    Students Section
                  </Message>
                </Content>
              </Header>
            </CardContainer>
          </CardBase>
        )}

        <DataGrid
          id='data-grid'
          rows={rows}
          columns={columns}
          pageSize={5}
          onRowClick={(param) => navigate(`view/${param.row._id}`)}
          style={{
            borderRadius: '10px',
            fontSize: '0.9rem',
            backgroundColor: 'white',
            padding: '0 1rem',
          }}
        />
      </Wrap>
      {showCard && (
        <CardBase>
          <CampFeeCard studentKey={studentKey} onClose={handleClose} />
        </CardBase>
      )}
    </>
  )
}
