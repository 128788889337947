import { Error } from '@mui/icons-material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../Layout'

import toast from 'react-hot-toast'
import { admissionSchema } from '../../../Schemas'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'
import DisplayCard from './DisplayCard'

const Wrap = styled.div`
  max-width: 1200px;
  /* background-color: #133b92; */
  background-color: #086102;

  color: white;
  background-size: cover;
  margin: 4rem auto;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 4rem;
  min-height: 100%;
  margin-bottom: 2rem;
  position: relative;
  @media (max-width: 1200px) {
    max-width: 1000px;
  }
  @media (max-width: 1000px) {
    max-width: 900px;
  }
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormText = styled.span`
  font-size: 1.1rem;
  margin-right: 10px;
  font-weight: 500;
  min-width: 150px;
  @media (max-width: 900px) {
    font-size: 0.8rem;
    min-width: 100px;
    font-weight: 400;
    margin-right: 5px;
  }
`
const FormTextNew = styled(FormText)`
  margin-right: 0px;
  min-width: 100px;
`
const MiddleText = styled(FormText)`
  font-size: 1.2rem;
  text-align: left;
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const InputSetNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const InputSetTwo = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 20px;
  padding: 1.5rem 0;
  width: 98%;
  @media (max-width: 900px) {
    width: 95%;
    margin-left: 5px;
  }
`

const FieldsHeading = styled.p`
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0.5rem;
  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem;
  }
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`

const SelectCat = styled.select`
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #0b0a45;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
  }
`
const SelectOpt = styled.option`
  /* width: 50%; */
  display: flex;
  background-color: #0b0a45;

  justify-content: center;
  align-items: center;
`
const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 800;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #ffffff;
  color: green;
`
const NextBtn = styled(SubmitBtn)`
  background: #ffee00;
  color: #000000;
  border: none;
`
const PrevBtn = styled(NextBtn)`
  background: #ff0000;
  border: none;
  color: white;
`

const FormInput = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`
const FormInputNew = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #fff;
    border-radius: 10px;
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`
const SplitBox = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: green;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
`
const ErrMsg = styled.p`
  font-size: 0.8rem;
  color: #e8ff3b;
  padding: 0.5rem;
  text-align: start;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const SelectedSports = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
`

const SportChip = styled.div`
  padding: 8px 12px;
  background-color: #0b0a45;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;
`

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
`

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
`

const CheckboxLabel = styled.label`
  margin: 5px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  margin-right: 10px;
`
const NewAdmission = () => {
  // all the states here
  const [showPageOne, setShowPageOne] = useState(true)
  const [selectedSports, setSelectedSports] = useState([])
  const [selectedClubs, setSelectedClubs] = useState([])

  const handleClubChange = (event) => {
    const club = event.target.name
    if (event.target.checked) {
      setSelectedClubs((prev) => [...prev, club])
    } else {
      setSelectedClubs((prev) => prev.filter((c) => c !== club))
    }
  }

  // all funcs

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      // If the date is not valid, return an empty string or handle it accordingly
      return ''
    }
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const GradeOptions = [
    'Choose',
    'PG',
    'Nursery',
    'KG',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
  ]
  const GenderOption = ['Choose', 'Male', 'Female', 'Prefer Not To Say']
  const SportsOptions = [
    'Cricket',
    'Football',
    'Hockey',
    'Badminton',
    'Table Tennis',
    'Basketball',
    'Volleyball',
    'Squash',
  ]
  const ClubsOptions = ['Riding', 'Swimming', 'Archery', 'Shooting']

  const handleAdmissions = async (e) => {
    e.preventDefault()
    try {
      const response = await axiosInstance.post('admission/submit/', {
        name: values.name,
        prevSchool: values.prevSchool,
        dob: values.dob,
        grade: values.grade,
        gender: values.gender,
        selectedSports,
        selectedClubs,
        sNationality: values.sNationality,
        fname: values.fname,
        fcnic: values.fcnic,
        fcell: values.fcell,
        femail: values.femail,
        foccupation: values.foccupation,
        feducation: values.feducation,
        fcompany: values.fcompany,
        fnationality: values.fnationality,
        mname: values.mname,
        moccupation: values.moccupation,
        mcompany: values.mcompany,
        mIsWorking: values.mIsWorking,
        meducation: values.meducation,
        mjob: values.mjob,
        mcell: values.mcell,
        address: values.address,
        others: values.others,
        flang: values.flang,
        olang: values.olang,
        sportsInterested: values.sportsInterested,
        siblings: {
          siblingOne: {
            name: values.siblings.siblingOne.name,
            grade: values.siblings.siblingOne.grade,
            section: values.siblings.siblingOne.section,
          },
          siblingTwo: {
            name: values.siblings.siblingTwo.name,
            grade: values.siblings.siblingTwo.grade,
            section: values.siblings.siblingTwo.section,
          },
          siblingThree: {
            name: values.siblings.siblingThree.name,
            grade: values.siblings.siblingThree.grade,
            section: values.siblings.siblingThree.section,
          },
          siblingFour: {
            name: values.siblings.siblingFour.name,
            grade: values.siblings.siblingFour.grade,
            section: values.siblings.siblingFour.section,
          },
        },
        medicalHistory: {
          allergies: values.medicalHistory.allergies,
          asthma: values.medicalHistory.asthma,
          speechDefect: values.medicalHistory.speechDefect,
          visionProblem: values.medicalHistory.visionProblem,
          hearingProblem: values.medicalHistory.hearingProblem,
          learningProblem: values.medicalHistory.learningProblem,
          compulsiveDisorder: values.medicalHistory.compulsiveDisorder,
          medication: values.medicalHistory.medication,
          otherCondition: values.medicalHistory.otherCondition,
        },
      })
      if (response.status === 200 || response.status === 201) {
        toast.success('Form Submitted Successfully', {
          duration: 5000,
        })
        handleReset()
      } else {
        toast.error('Submission Failed. Please try again!', {
          duration: 5000,
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      // setIsLoading(false)
    }
  }
  // handling forms data
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      prevSchool: '',
      dob: '',
      grade: '',
      gender: '',
      sNationality: '',
      fname: '',
      fcnic: '',
      fcell: '',
      femail: '',
      fnationality: '',
      foccupation: '',
      feducation: '',
      fcompany: '',
      mname: '',
      moccupation: '',
      mIsWorking: '',
      meducation: '',
      mcompany: '',
      mjob: '',
      mcell: '',
      address: '',
      others: '',
      flang: '',
      olang: '',
      sportsInterested: [],
      siblings: {
        siblingOne: {
          name: '',
          grade: '',
          section: '',
        },
        siblingTwo: {
          name: '',
          grade: '',
          section: '',
        },
        siblingThree: {
          name: '',
          grade: '',
          section: '',
        },
        siblingFour: {
          name: '',
          grade: '',
          section: '',
        },
      },
      medicalHistory: {
        allergies: '',
        asthma: '',
        speechDefect: '',
        visionProblem: '',
        hearingProblem: '',
        learningProblem: '',
        compulsiveDisorder: '',
        medication: '',
        otherCondition: '',
      },
    },
    validationSchema: admissionSchema,
    handleSubmit: handleAdmissions,
  })

  const handleSelectSport = (event) => {
    const sport = event.target.value
    if (sport) {
      setSelectedSports((prev) => [...prev, sport])
    }
  }

  const handleRemoveSport = (sport) => {
    setSelectedSports((prev) => prev.filter((s) => s !== sport))
  }

  const availableSports = SportsOptions.filter(
    (sport) => !selectedSports.includes(sport)
  )

  const handleReset = () => {
    resetForm()
    setSelectedSports([])
    setSelectedClubs([])
  }
  return (
    <Layout>
      <Topbar title='Admissions / Add' />
      <>
        <Wrap id='booking-form-wrap'>
          {showPageOne && (
            <>
              <FieldsHeading>Admission Form</FieldsHeading>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Student's Name: </FormText>
                    <FormInput
                      placeholder='Enter Name...'
                      type='text'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='name'
                    />
                  </InputSet>
                  {errors.name && touched.name && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.name}
                    </ErrMsg>
                  )}
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Previous School: </FormText>
                    <FormInput
                      placeholder='Enter Previous School...'
                      type='text'
                      value={values.prevSchool}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='prevSchool'
                    />
                  </InputSet>
                  {errors.prevSchool && touched.prevSchool && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} />{' '}
                      {errors.prevSchool}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>

              <InputSetTwo>
                <FormText>Class: </FormText>
                <SelectCat
                  name='grade'
                  value={values.grade}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id='grade'
                >
                  {GradeOptions.map((category) => (
                    <SelectOpt key={category} value={category}>
                      {category}
                    </SelectOpt>
                  ))}
                </SelectCat>
              </InputSetTwo>
              {errors.grade && touched.grade && (
                <ErrMsg>
                  <Error style={{ marginRight: '5px' }} /> {errors.grade}
                </ErrMsg>
              )}
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>DOB: </FormText>
                    <FormInput
                      type='date'
                      value={formatDate(values.dob)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='dob'
                    />
                  </InputSet>
                  {errors.dob && touched.dob && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.dob}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSetTwo>
                    <FormText>Gender: </FormText>
                    <SelectCat
                      name='gender'
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {GenderOption.map((category) => (
                        <SelectOpt key={category} value={category}>
                          {category}
                        </SelectOpt>
                      ))}
                    </SelectCat>
                  </InputSetTwo>
                  {errors.gender && touched.gender && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.gender}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Nationality: </FormText>
                    <FormInput
                      placeholder='Enter Student Nationality...'
                      type='text'
                      value={values.sNationality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='sNationality'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Father's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Father Name...'
                      type='text'
                      value={values.fname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='fname'
                    />
                  </InputSet>
                  {errors.fname && touched.fname && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.fname}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> CNIC: </FormText>
                    <FormInput
                      placeholder='Enter CNIC ...'
                      type='text'
                      value={values.fcnic}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='fcnic'
                    />
                  </InputSet>
                  {errors.fcnic && touched.fcnic && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.fcnic}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#...'
                      type='text'
                      value={values.fcell}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='fcell'
                    />
                  </InputSet>
                  {errors.fcell && touched.fcell && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.fcell}
                    </ErrMsg>
                  )}
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Email: </FormText>
                    <FormInput
                      placeholder='Enter Fathers Email...'
                      type='text'
                      value={values.femail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='femail'
                    />
                  </InputSet>
                  {errors.fcnic && touched.fcnic && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.fcnic}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Nationality: </FormText>
                    <FormInput
                      placeholder='Enter Fathers Nationality...'
                      type='text'
                      value={values.fnationality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='fnationality'
                    />
                  </InputSet>
                  {errors.fnationality && touched.fnationality && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} />{' '}
                      {errors.fnationality}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Occupation: </FormText>
                    <FormInput
                      placeholder='Enter Fathers occupation...'
                      type='text'
                      value={values.foccupation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='foccupation'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Company Name: </FormText>
                    <FormInput
                      placeholder='Company Name (if any)'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fcompany}
                      id='fcompany'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Mother's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Name: </FormText>
                    <FormInput
                      placeholder='Enter Mothers Name...'
                      type='text'
                      value={values.mname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='mname'
                    />
                  </InputSet>
                  {errors.mname && touched.mname && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.mname}
                    </ErrMsg>
                  )}
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Cell#: </FormText>
                    <FormInput
                      placeholder='Enter Cell#..'
                      type='text'
                      value={values.mcell}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='mcell'
                    />
                  </InputSet>
                  {errors.mcell && touched.mcell && (
                    <ErrMsg>
                      <Error style={{ marginRight: '5px' }} /> {errors.mcell}
                    </ErrMsg>
                  )}
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText> Occupation: </FormText>
                    <FormInput
                      placeholder='Enter Occupation...'
                      type='text'
                      value={values.moccupation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='moccupation'
                    />
                  </InputSet>
                </ErrorWrap>

                <ErrorWrap>
                  <InputSet>
                    <FormText>Company Name: </FormText>
                    <FormInput
                      placeholder='Company Name (if any)'
                      type='text'
                      value={values.mcompany}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='mcompany'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Additional Information</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>First Language spoken at home: </FormText>
                    <FormInput
                      placeholder='Enter Language...'
                      type='text'
                      value={values.flang}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='flang'
                    />
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Any Other: </FormText>
                    <FormInput
                      placeholder='Enter Language...'
                      type='text'
                      value={values.olang}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='olang'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Address: </FormText>
                    <FormInput
                      placeholder='Enter Address...'
                      type='text'
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='address'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Other Information / Notes :</FormText>
                    <FormInput
                      placeholder='Other info...'
                      type='text'
                      value={values.others}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='others'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <SubSet>
                <NextBtn onClick={() => setShowPageOne(false)}>
                  <span>Next</span>
                </NextBtn>
              </SubSet>
            </>
          )}
          {!showPageOne && (
            <>
              <SplitBox>Sports & Club Preferences</SplitBox>
              <InputWrap>
                <InputSetTwo>
                  <FormText>Sports:</FormText>
                  <SelectCat
                    name='sportsInterested'
                    onChange={handleSelectSport}
                    value=''
                  >
                    <SelectOpt value=''>Choose</SelectOpt>
                    {availableSports.map((option) => (
                      <SelectOpt key={option} value={option}>
                        {option}
                      </SelectOpt>
                    ))}
                  </SelectCat>
                </InputSetTwo>
              </InputWrap>
              <InputSet>
                <SelectedSports>
                  {selectedSports.map((sport) => (
                    <SportChip key={sport}>
                      {sport}
                      <RemoveButton onClick={() => handleRemoveSport(sport)}>
                        &times;
                      </RemoveButton>
                    </SportChip>
                  ))}
                </SelectedSports>
              </InputSet>
              <InputWrap>
                <InputSet>
                  <FormText>Clubs:</FormText>
                  <CheckboxContainer>
                    {ClubsOptions.map((club) => (
                      <CheckboxLabel key={club}>
                        <Checkbox
                          type='checkbox'
                          name={club}
                          checked={selectedClubs.includes(club)}
                          onChange={handleClubChange}
                        />
                        {club}
                      </CheckboxLabel>
                    ))}
                  </CheckboxContainer>
                </InputSet>
              </InputWrap>

              <SplitBox>Siblings Information</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <MiddleText>
                    Name of brothers and sisters studying in the same system....
                  </MiddleText>
                </ErrorWrap>
              </InputWrap>

              <InputWrap>
                <InputSet>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingOne.name'
                    placeholder='Enter Name ...'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.siblings.siblingOne.name}
                    id='SiblingOneName'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingOne.grade'
                    placeholder='Enter Class ...'
                    type='text'
                    value={values.siblings.siblingOne.grade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingOneClass'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingOne.section'
                    placeholder='Enter Section ...'
                    type='text'
                    value={values.siblings.siblingOne.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingOneSection'
                  />
                </InputSet>
              </InputWrap>
              <InputWrap>
                <InputSet>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingTwo.name'
                    placeholder='Enter Name ...'
                    type='text'
                    value={values.siblings.siblingTwo.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingTwoName'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingTwo.grade'
                    placeholder='Enter Class ...'
                    type='text'
                    value={values.siblings.siblingTwo.grade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingTwoGrade'
                  />
                </InputSet>
                <InputSet>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingTwo.section'
                    placeholder='Enter Section ...'
                    type='text'
                    value={values.siblings.siblingTwo.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingTwoSection'
                  />
                </InputSet>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingThree.name'
                    placeholder='Enter Name ...'
                    type='text'
                    value={values.siblings.siblingThree.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingThreeName'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingThree.grade'
                    placeholder='Enter Class ...'
                    type='text'
                    value={values.siblings.siblingThree.grade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingThreeClass'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingThree.section'
                    placeholder='Enter Section ...'
                    type='text'
                    value={values.siblings.siblingThree.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingThreeSection'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Name: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingFour.name'
                    placeholder='Enter Name ...'
                    type='text'
                    value={values.siblings.siblingFour.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingFourName'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Class: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingFour.grade'
                    placeholder='Enter Class ...'
                    type='text'
                    value={values.siblings.siblingFour.grade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingFourGrade'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Section: </FormTextNew>
                  <FormInputNew
                    name='siblings.siblingFour.section'
                    placeholder='Enter Section ...'
                    type='text'
                    value={values.siblings.siblingFour.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='SiblingFourSection'
                  />
                </InputSetNew>
              </InputWrap>
              <SplitBox>Medical History</SplitBox>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Allergies: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.allergies'
                    placeholder='Any Allergy? ...'
                    type='text'
                    value={values.medicalHistory.allergies}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='allergies'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Asthma: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.asthma'
                    placeholder='Asthma?'
                    type='text'
                    value={values.medicalHistory.asthma}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='asthma'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Speech Defects: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.speechDefect'
                    placeholder='Enter Defects...'
                    type='text'
                    value={values.medicalHistory.speechDefect}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='speechDefect'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <InputSetNew>
                  <FormTextNew>Vision Problems : </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.visionProblem'
                    placeholder='Any Vision Problems?'
                    type='text'
                    value={values.medicalHistory.visionProblem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='visionProblem'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Hearing Problems: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.hearingProblem'
                    placeholder='Any Hearing Problems?'
                    type='text'
                    value={values.medicalHistory.hearingProblem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='hearingProblem'
                  />
                </InputSetNew>
                <InputSetNew>
                  <FormTextNew>Learning Difficulties: </FormTextNew>
                  <FormInputNew
                    name='medicalHistory.learningProblem'
                    placeholder='Any Learning Difficulties?'
                    type='text'
                    value={values.medicalHistory.learningProblem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='learningProblem'
                  />
                </InputSetNew>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Compulsive Disorders: </FormText>
                    <FormInput
                      name='medicalHistory.compulsiveDisorder'
                      placeholder='Any Compulsive Disorders?'
                      type='text'
                      value={values.medicalHistory.compulsiveDisorder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='compulsiveDisorder'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Is your child on any medication? </FormText>
                    <FormInput
                      name='medicalHistory.medication'
                      placeholder='meditation?'
                      type='text'
                      value={values.medicalHistory.medication}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='medication'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>
                      Any other conditions which may affect your child's
                      activities in school?
                    </FormText>
                    <FormInput
                      name='medicalHistory.otherCondition'
                      placeholder='Details Here...'
                      type='text'
                      value={values.medicalHistory.otherCondition}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='otherCondition'
                    />
                  </InputSet>
                </ErrorWrap>
              </InputWrap>

              <SubSet>
                <PrevBtn onClick={() => setShowPageOne(true)}>
                  <span>Previous</span>
                </PrevBtn>
                <SubmitBtn
                  style={{ marginLeft: '10px' }}
                  onClick={handleAdmissions}
                >
                  Submit
                </SubmitBtn>
              </SubSet>
            </>
          )}
        </Wrap>
      </>
    </Layout>
  )
}

export default NewAdmission
