import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'
import HistoryDeletedTable from '../../../../components/Students/Admission/Fee/HistoryDeletedTable'
import { axiosInstance } from '../../../../config'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`
const WrapBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 1rem 2rem 2rem 0rem;
  border-radius: 10px;
  border: 1px solid white;
`
const BarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
`
const BarRight = styled(BarLeft)`
  justify-content: flex-end;
  padding: 2rem;
`

const BarContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  flex-direction: column;
`
const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`
const TextBig = styled.p`
  font-size: 3rem;
  font-weight: 700;
`

const ViewDeletedInvoices = () => {
  const [stuName, setStuName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [gradeName, setGradeName] = useState('')
  const [dob, setDob] = useState('')

  const params = useParams()

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  useQuery(
    'fee-history-person-deleted',
    async () => {
      const res = await axiosInstance.get(`/admission/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setStuName(data.name || '')
        setFatherName(data.fname || '')
        setGradeName(data.grade || '')
        setDob(formatDate(data.dob) || '')
      },
    }
  )

  return (
    <Layout>
      <Topbar title='Fee History' />
      <Wrap>
        <WrapBar>
          <BarLeft>
            <BarContent>
              <Text>Name : {stuName}</Text>
              <Text>Guardian : {fatherName}</Text>
              <Text>Class : {gradeName}</Text>
              <Text>Date of Birth : {dob}</Text>
            </BarContent>
          </BarLeft>
          <BarRight>
            <TextBig> DELETED HISTORY</TextBig>
          </BarRight>
        </WrapBar>
        <HistoryDeletedTable />
      </Wrap>
    </Layout>
  )
}

export default ViewDeletedInvoices
