// FeePageComponent.js
import { CloudDownload } from '@mui/icons-material'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'
import FeeSlipMaker from '../../../../components/Students/Admission/Fee/FeeSlipMaker'
import FeeMaker from '../../../../components/Students/Enrolled/Fee/FeeMaker'
import { axiosInstance } from './../../../../config'

const Row = styled.div`
  position: relative;
  height: 100vh;
  background-color: #ffffff;
  min-width: 1200px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

const Wrap = styled.div`
  display: flex;
`

const WrapBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 1rem 2rem 2rem 0rem;
  border-radius: 10px;
  border: 1px solid white;
`
const BarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
`
const BarRight = styled(BarLeft)`
  justify-content: flex-end;
  padding: 2rem;
`
const TextBig = styled.p`
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
`

const BarContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  flex-direction: column;
`
const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`
const Column = styled.div`
  float: left;
  width: 31%;
  padding: 10px 2rem;

  border-right: 1px dotted #000;
  height: 100%;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  font-size: 0.8em;
`

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
`

const TextBox = styled.strong`
  justify-content: center;
  padding: 0.5rem 0;
`

const DateWrap = styled.div`
  display: flex;
  padding: 1rem 0;
  font-size: 0.8em;
`

const FloatRight = styled.div`
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: flex-end;
  font-size: 0.8em;
`

const Table = styled.table`
  margin-top: 10px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
`

const TableRow = styled.tr`
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.35em;
  font-size: 0.9rem;
`

const TableHeader = styled.th`
  padding: 0.625em;
  border: 1px solid #000;
`

const TableCell = styled.td`
  padding: 0.625em;
  border: 1px solid #000;
  font-size: 0.7em;
  letter-spacing: 2px;
`

const HorizontalRule = styled.hr`
  border-top: 1px solid #000;
`

const DownloadButton = styled.button`
  background-color: #026806;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
`
const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  @media print {
    display: none;
  }
`

const FeePage = () => {
  const params = useParams()
  const [gradeName, setGradeName] = useState('')
  const [studentName, setStudentName] = useState('')
  const [items, setItems] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [totalAmount, setTotalAmount] = useState(0)
  const [fatherName, setFatherName] = useState('')
  const [sec, setSec] = useState('')

  const savePdfRef = useRef(null)
  const editRef = useRef(null)
  const deleteRef = useRef(null)

  useEffect(() => {
    const total = items.reduce(
      (acc, item) => acc + parseFloat(item.amount.replace(/,/g, '')),
      0
    )
    setTotalAmount(total)
  }, [items])

  const generatePDF = () => {
    if (savePdfRef.current) {
      savePdfRef.current.style.display = 'none'
    }
    if (editRef.current) {
      editRef.current.style.display = 'none'
    }
    if (deleteRef.current) {
      deleteRef.current.style.display = 'none'
    }
    const input = document.getElementById('booking-form-wrap')

    // input.style.width = '1200px' // Adjust this value based on the desired output
    // input.style.height = '700px' // Adjust this value based on the desired output

    html2canvas(input, {
      scale: 2,
      logging: true,
      dpi: window.devicePixelRatio * 192,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0) // Use JPEG format with quality 1.0 (no compression)

      const pdfWidth = 320 // A4 dimensions in mm
      const pdfHeight = 187

      const imgWidth = pdfWidth
      const imgHeight = pdfHeight

      const pdf = new jsPDF('landscape', 'mm', 'a4') // Set to landscape orientation

      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight)

      pdf.save(`${studentName}.pdf`)

      if (savePdfRef.current) {
        savePdfRef.current.style.display = 'block'
      }
      if (editRef.current) {
        editRef.current.style.display = 'block'
      }
      if (deleteRef.current) {
        deleteRef.current.style.display = 'block'
      }
    })
  }

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value) // This assumes onDueDateChange is correctly set up to update state
  }

  const addItemsToSlip = (newItems) => {
    // Replace existing items with newItems, which are the selected items from the form
    setItems(newItems)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return `${('0' + date.getDate()).slice(-2)}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`
  }

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN').format(amount)
  }
  const calculateTotalWithFine = (amount) => {
    const fineRate = 1.5 // 1.5%
    const fineAmount = amount * (fineRate / 100)
    const totalWithFine = amount + fineAmount
    return totalWithFine
  }

  useQuery(
    'en-specific-student',
    async () => {
      const res = await axiosInstance.get(`/student/${params.id}`)

      return res.data
    },
    {
      onSuccess: (data) => {
        setStudentName(data.personalInfo.name || '')
        setFatherName(data.guardianInfo.guardianName.fatherName || '')
        setGradeName(data.classInfo.className || '')
        setSec(data.classInfo.section || '')
      },
    }
  )

  return (
    <Layout>
      <Topbar title='Fee Details' />
      <Wrap>
        <WrapBar>
          <BarLeft>
            <BarContent>
              <Text>Name : {studentName}</Text>
              <Text>Guardian : {fatherName}</Text>
              <Text>Class : {gradeName}</Text>
              <Text>Section : {sec}</Text>
            </BarContent>
          </BarLeft>
          <BarRight>
            <TextBig>STUDENT Profile</TextBig>
          </BarRight>
        </WrapBar>
      </Wrap>
      <FeeMaker
        onAddItems={addItemsToSlip}
        dueDate={dueDate}
        onDueDateChange={handleDueDateChange}
      />
      {/* <Btn onClick={generatePDF}>
        <DownloadButton>
          <CloudDownload style={{ fontSize: '1.5rem', marginRight: '5px' }} />
          Print
        </DownloadButton>
      </Btn> */}
      {/* <Row id='booking-form-wrap'>
        <Column>
          <JustifyBetween>
            <TextBox>Payable by due date</TextBox>
            <TextBox>Bank Copy</TextBox>
          </JustifyBetween>

          <FlexContainer>
            <FlexColumn>
              <span>Due Date:</span>
              <span>{formatDate(dueDate)}</span>

              <span>Name:</span>
              <span>{studentName}</span>
            </FlexColumn>
            <FlexColumn>
              <span>Account: Meezan Bank</span>
              <span>The Orion School</span>
              <span>Class</span>
              <span>{gradeName}</span>
            </FlexColumn>
          </FlexContainer>
          <FlexContainer>
            <Barcode value='ABC123' height={50} className='barcode' />
          </FlexContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader scope='col left' colSpan='2'>
                  Description
                </TableHeader>
                <TableHeader scope='col right'>Amount</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell colSpan='2'>{item.description}</TableCell>
                  <TableCell>{formatAmount(item.amount)}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <HorizontalRule />
          <JustifyBetween>
            <TextBox>Payable by due date</TextBox>
            <span>Rs. {formatAmount(totalAmount)}</span>
          </JustifyBetween>
          <HorizontalRule />

          <JustifyBetween>
            <TextBox>Payable after due date</TextBox>
            <span>Rs. {formatAmount(calculateTotalWithFine(totalAmount))}</span>
          </JustifyBetween>
          <HorizontalRule />
          <FlexColumn>
            <span>For Bank Use Only</span>
            <span>Received Payment Rs.</span>
          </FlexColumn>
          <br />
          <FloatRight>
            <span>Signature and Stamp</span>
            <span>Bank Officer</span>
          </FloatRight>
          <br />
          <br />
          <DateWrap>
            <span>Date:</span>
            <span>_____________________</span>
          </DateWrap>
        </Column>
        <Column>
          <JustifyBetween>
            <TextBox>Invoice # INV/2024</TextBox>
            <TextBox>Office Copy</TextBox>
          </JustifyBetween>
          <FlexContainer>
            <FlexColumn>
              <span>Due Date:</span>
              <span>{formatDate(dueDate)}</span>

              <span>Name:</span>
              <span>{studentName}</span>
            </FlexColumn>
            <FlexColumn>
              <span>Account: Meezan Bank</span>
              <span>The Orion School</span>
              <span>Class</span>
              <span>{gradeName}</span>
            </FlexColumn>
          </FlexContainer>
          <FlexContainer>
            <Barcode value='ABC123' height={50} className='barcode' />
          </FlexContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader scope='col left' colSpan='2'>
                  Description
                </TableHeader>
                <TableHeader scope='col right'>Amount</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell colSpan='2'>{item.description}</TableCell>
                  <TableCell>{formatAmount(item.amount)}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <HorizontalRule />
          <JustifyBetween>
            <TextBox>Payable by due date</TextBox>
            <span>Rs. {formatAmount(totalAmount)}</span>
          </JustifyBetween>
          <HorizontalRule />

          <JustifyBetween>
            <TextBox>Payable after due date</TextBox>
            <span>Rs. {formatAmount(calculateTotalWithFine(totalAmount))}</span>
          </JustifyBetween>
          <HorizontalRule />
          <FlexColumn>
            <span>For Bank Use Only</span>
            <span>Received Payment Rs.</span>
          </FlexColumn>
          <br />
          <FloatRight>
            <span>Signature and Stamp</span>
            <span>Bank Officer</span>
          </FloatRight>
          <br />
          <br />
          <DateWrap>
            <span>Date:</span>
            <span>_____________________</span>
          </DateWrap>
        </Column>
        <Column>
          <JustifyBetween>
            <TextBox>Invoice # INV/2024</TextBox>
            <TextBox>Student Copy</TextBox>
          </JustifyBetween>
          <FlexContainer>
            <FlexColumn>
              <span>Due Date:</span>
              <span>{formatDate(dueDate)}</span>

              <span>Name:</span>
              <span>{studentName}</span>
            </FlexColumn>
            <FlexColumn>
              <span>Account: Meezan Bank</span>
              <span>The Orion School</span>
              <span>Class</span>
              <span>{gradeName}</span>
            </FlexColumn>
          </FlexContainer>
          <FlexContainer>
            <Barcode value='ABC123' height={50} className='barcode' />
          </FlexContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader scope='col left' colSpan='2'>
                  Description
                </TableHeader>
                <TableHeader scope='col right'>Amount</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell colSpan='2'>{item.description}</TableCell>
                  <TableCell>{formatAmount(item.amount)}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <HorizontalRule />
          <JustifyBetween>
            <TextBox>Payable by due date</TextBox>
            <span>Rs. {formatAmount(totalAmount)}</span>
          </JustifyBetween>
          <HorizontalRule />

          <JustifyBetween>
            <TextBox>Payable after due date</TextBox>
            <span>Rs. {formatAmount(calculateTotalWithFine(totalAmount))}</span>
          </JustifyBetween>
          <HorizontalRule />
          <FlexColumn>
            <span>For Bank Use Only</span>
            <span>Received Payment Rs.</span>
          </FlexColumn>
          <br />
          <FloatRight>
            <span>Signature and Stamp</span>
            <span>Bank Officer</span>
          </FloatRight>
          <br />
          <br />
          <DateWrap>
            <span>Date:</span>
            <span>_____________________</span>
          </DateWrap>
        </Column>
      </Row> */}
    </Layout>
  )
}

export default FeePage
