import React from 'react'

import { useQuery } from 'react-query'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import Loader from '../../Loader/Loader'
import { axiosInstance } from '../../config'

const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`
const Heading = styled.h1`
  color: #ffffff;
  margin-bottom: 30px;
`
const Wrap = styled.div`
  min-width: 1200px;
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
`

const getMaxValue = (data) => {
  const maxCount = Math.max(...data.map((item) => item.count))
  return maxCount + 4
}
const FinanceGradeCount = () => {
  const { data, status } = useQuery('get-count', async () => {
    const res = await axiosInstance.get(`student/class-counts`)
    return res.data
  })

  if (status === 'loading') {
    return (
      <LoaderWrap>
        <Loader msg='Loading Admissions...' />
      </LoaderWrap>
    )
  }

  if (status === 'error') {
    return (
      <LoaderWrap>
        <Loader msg='Failed to load data.' />
      </LoaderWrap>
    )
  }

  const maxValue = getMaxValue(data)
  return (
    <Wrap>
      <ResponsiveContainer width='100%' height={300}>
        <Heading>Students By Class</Heading>

        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' stroke='#ffffff' />
          <XAxis dataKey='_id' stroke='#ffffff' />
          <YAxis domain={[0, maxValue]} stroke='#ffffff' />
          <Tooltip />
          <Bar dataKey='count' fill='#0cf3f3'>
            <LabelList dataKey='count' position='top' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Wrap>
  )
}

export default FinanceGradeCount
