import {
  Delete,
  DeleteForever,
  Edit,
  KeyboardBackspace,
  Visibility,
} from '@mui/icons-material'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components'
import Layout from '../../../Layout'
import Loader from '../../../Loader/Loader'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 8px; */
`

const FormText = styled.span`
  font-weight: 700;
  min-width: 110px;
`

const FormContent = styled.p`
  font-weight: 600;
  width: 100%;
  border-bottom: 0.5px solid #00000096;
  padding: 10px 0;
  min-height: 18px;
  color: #000000;
  outline: none;
  background: transparent;
  padding: 0.3rem;
  /* border-radius: 5px; */
  min-width: 200px;
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: capitalize;
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0.2rem 0;
  padding: 0 0.4rem;
`

const FieldsHeading = styled.p`
  font-size: 1rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
  color: #000000;
  padding: 0.3rem;
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 17px;
  border-radius: 300px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  min-width: 150px;
  &.green {
    background-color: green;
  }
  &.blue {
    background-color: blue;
  }
  &.red {
    background-color: red;
  }
  &.darkGreen {
    background-color: #003700;
  }
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 7, 35, 0.829); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const LogoImg = styled.img`
  width: 70px;
  min-width: 40px;
`
const LogoInfo = styled.p`
  font-size: 10px;
  text-transform: lowercase;
  font-weight: 300;
`
const FooterInfo = styled(LogoInfo)`
  margin-top: 2rem;
`
const SplitBox = styled.div`
  width: 100%;
  font-weight: 800;
  letter-spacing: 2px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  padding: 0.5rem;
  margin: 10px 0;
  margin-top: 12px;
  border: 1px solid black;
`
const A4Wrap = styled.div`
  width: 8.27in; // Width of A4 paper
  min-height: 11.69in; // Height of A4 paper
  padding: 1rem; // Padding around the content
  margin: auto; // Center the wrap on the page
  background-color: #ffffff;
  color: #000000;
  position: relative;
`
const SignatureBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-top: 2rem;
`
const StampWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const SignatureText = styled.p`
  font-size: 1rem;
  font-weight: 500;
`
const SignatureMini = styled.p`
  font-weight: 300;
  font-size: 0.4rem;
  text-align: center;
  margin-top: 5px;
`
const StampImg = styled.img`
  max-width: 100px;
  margin-top: 10px;
`
const PunchLine = styled.p`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  font-weight: 300;
`

const ViewCamper = () => {
  // all the states here
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const [classInfo, setClassInfo] = useState('')
  const [personalInfo, setPersonalInfo] = useState('')
  const [guardianInfo, setGuardianInfo] = useState({
    guardianName: { motherName: '', fatherName: '' },
    guardianOccupation: {
      motherOcc: '',
      fatherOcc: '',
      motherCom: '',
      fatherCom: '',
    },
    guardianContact: {
      fatherCell: '',
      motherCell: '',
      fatherCNIC: '',
      motherCNIC: '',
      fatherEmail: '',
      fatherNationality: '',
    },
    otherInfo: { address: '', flang: '', olang: '' },
  })

  const [interestIn, setInterestIn] = useState({
    sports: [],
    clubs: [],
  })

  const navigate = useNavigate()

  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.delete(`/summerCamp/${params.id}`)
      toast.error('Form Deleted Successfully', {
        duration: 4000,
      })
      navigate('/students/summer-camp')
    } catch (err) {
      console.log(err)
    }
  }

  // get request data
  useQuery(
    'specific-view-student',
    async () => {
      const res = await axiosInstance.get(`/summerCamp/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        const defaultGuardianInfo = {
          guardianName: { motherName: '', fatherName: '' },
          guardianOccupation: {
            motherOcc: '',
            fatherOcc: '',
            motherCom: '',
            fatherCom: '',
          },
          guardianContact: {
            fatherCell: '',
            motherCell: '',
            fatherCNIC: '',
            motherCNIC: '',
            fatherEmail: '',
            fatherNationality: '',
          },
          otherInfo: { address: '', flang: '', olang: '' },
        }

        setClassInfo(data.classInfo || {})
        setInterestIn(
          data.interestIn || {
            sports: [],
            clubs: [],
          }
        )
        setPersonalInfo(data.personalInfo || {})
        setGuardianInfo({
          ...defaultGuardianInfo,
          ...data.guardianInfo,
          guardianName: {
            ...defaultGuardianInfo.guardianName,
            ...data.guardianInfo?.guardianName,
          },
          guardianOccupation: {
            ...defaultGuardianInfo.guardianOccupation,
            ...data.guardianInfo?.guardianOccupation,
          },
          guardianContact: {
            ...defaultGuardianInfo.guardianContact,
            ...data.guardianInfo?.guardianContact,
          },
          otherInfo: {
            ...defaultGuardianInfo.otherInfo,
            ...data.guardianInfo?.otherInfo,
          },
        })
        setIsLoading(false)
      },
    }
  )

  return (
    <>
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            ${A4Wrap}, ${A4Wrap} * {
              visibility: visible;
            }
            ${A4Wrap} {
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              padding: 0px; // Reduced padding in print mode
              width: 100%;
              height: 100%;
            }
            .SubSet, .SubSet * { 
              display: none; 
            }
          }
        `}
      </style>

      <Layout>
        <Topbar title='Student / Profile' />
        <form>
          <A4Wrap id='booking-form-wrap'>
            <LogoWrap>
              <LogoImg src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logobw.png?raw=true' />
              <FieldsHeading>The Orion School </FieldsHeading>
              <LogoInfo>www.theorionschool.com</LogoInfo>
              <LogoInfo>+92 307 7879977</LogoInfo>
              <LogoInfo>help@orionedu.net</LogoInfo>
            </LogoWrap>
            <SplitBox>Student Profile</SplitBox>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Name: </FormText>
                  <FormContent>{personalInfo.name || ' '}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Class: </FormText>
                  <FormContent>{classInfo.className || ''}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Type: </FormText>
                  <FormContent>Summer Camp</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Gender: </FormText>
                  <FormContent>{personalInfo.gender || ''}</FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>DOB: </FormText>
                  <FormContent>
                    {formatDate(personalInfo.dateOfBirth) || ' '}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Previous School:: </FormText>
                  <FormContent>{personalInfo.prevSchool || ''}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SplitBox>Father's Profile</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Name: </FormText>
                  <FormContent>
                    {guardianInfo.guardianName.fatherName || 'N/A'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Cell#: </FormText>
                  <FormContent>
                    {guardianInfo.guardianContact.fatherCell || 'N/A'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>CNIC: </FormText>
                  <FormContent>
                    {guardianInfo.guardianContact.fatherCNIC || ''}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Occupation: </FormText>
                  <FormContent>
                    {guardianInfo.guardianOccupation.fatherOcc || ' N/A'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <SplitBox> Mother's Profile</SplitBox>

            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Name: </FormText>
                  <FormContent>
                    {guardianInfo.guardianName.motherName || ' N/A'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText> Cell#: </FormText>
                  <FormContent>
                    {guardianInfo.guardianContact.motherCell || ' N/A'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <SplitBox>Other Information</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Address: </FormText>
                  <FormContent>{personalInfo.address || ' N/A'}</FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>

            <SplitBox>Sports & Club Preferences</SplitBox>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Sports Interested In </FormText>
                  <FormContent>
                    {interestIn.sports.join(' ,') || 'None'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <InputWrap>
              <ErrorWrap>
                <InputSet>
                  <FormText>Clubs Interested In </FormText>
                  <FormContent>
                    {interestIn.clubs.join(' ,') || 'None'}
                  </FormContent>
                </InputSet>
              </ErrorWrap>
            </InputWrap>
            <SignatureBox>
              <StampWrap>
                <SignatureText>Admission Officer</SignatureText>
                <StampImg src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/stamp.png?raw=true' />
              </StampWrap>
              <SignatureText>
                Parent's Signature <br />{' '}
                <SignatureMini>
                  ({guardianInfo.guardianName.fatherName} or{' '}
                  {guardianInfo.guardianName.motherName})
                </SignatureMini>
              </SignatureText>
            </SignatureBox>
            <LogoWrap>
              <PunchLine>
                Your adventure awaits...
                <br />
                Welcome aboard! We're excited to have you join us at summer
                camp.{' '}
              </PunchLine>
              <LogoInfo>www.theorionschool.com</LogoInfo>
            </LogoWrap>

            <LogoWrap>
              <FooterInfo>
                www.theorionschool.com | +92 307 7879977 | help@orionedu.net
              </FooterInfo>
            </LogoWrap>

            {isLoading && (
              <CardBase>
                <Loader msg='Loading Student Profile Card...' />
              </CardBase>
            )}
          </A4Wrap>
          <SubSet>
            <SubmitBtn className='green' onClick={goBack}>
              <KeyboardBackspace style={{ marginRight: '5px' }} />
              Back
            </SubmitBtn>
            <Link to={`/students/summer-camp/edit/${params.id}`}>
              <SubmitBtn className='blue'>
                <Edit style={{ marginRight: '5px' }} />
                Edit
              </SubmitBtn>
            </Link>
            <SubmitBtn className='red' onClick={handleDelete}>
              <Delete style={{ marginRight: '5px' }} />
              Delete
            </SubmitBtn>

            <SubmitBtn className='darkGreen' onClick={() => window.print()}>
              <Visibility style={{ marginRight: '5px' }} />
              Print
            </SubmitBtn>
          </SubSet>
        </form>
      </Layout>
    </>
  )
}

export default ViewCamper
